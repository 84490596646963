import crypto from 'crypto';
import sha256 from 'sha256';

export const BOARD_ROWS    = 3;
export const BOARD_LENGTH  = 3;
export const TRINKET_COUNT = 6;
export const PROD_WEB_SOCKET_URL = 'wss://autobattler.io/api/game-connection';
export const DEV_WEB_SOCKET_URL  = 'ws://localhost:10202/api/game-connection';
//export const DEV_WEB_SOCKET_URL = PROD_WEB_SOCKET_URL;

export const AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY = 'autobattlerGameTokens';
export const AUTOBATTLER_ACCOUNT_TOKEN_LOCALSTORAGE_KEY = 'autobattlerAccountToken';
export const AUTOBATTLER_INVITE_TOKEN_LOCALSTORAGE_KEY = 'autobattlerInviteToken';

export type Ability = (
  'heavy' |
  'ranged' |
  'taunt' |
  'invisible' |
  'strike-all'
);

export interface IBoardCard {
  c: string;
  atk: number;
  def: number;
  level: number;
  gold: number;
  hp: number;
  img?: string;
  dealsPoison?: boolean;
  poison?: number;
  shields?: number;
  stun?: number;
  counter?: number;
  text: string;
  isTrinket?: boolean;
  isSpell?: boolean;
  // Specific mechanics.
  abilities?: Ability[];
  damageReduction?: number;
}

export interface IBoardCell {
  d: IBoardCard | null;
  cost: number | null;
}

export interface ITrinket {
  c: string;
  text: string;
  counters: number;
}

export interface IPlayerState {
  index: number;
  hp: number;
  xp: number;
  xpLeft: number;
  xpToLevel: number;
  //level: number;
  //minShopLevel: number;
  shopLevel: number;
  minShopLevel: number;
  shopUpgradeCost: number | null;
  trinketLevel: number;
  minTrinketLevel: number;
  trinketUpgradeCost: number | null;
  gold: number;
  rerolls: number;
  rerollsMax: number;
  rerollUpgradeCost: number | null;
  rerollCost: number;
  offerings: IBoardCell[];
  offeringsLocked: boolean;
  board: IBoardCell[][];
  trinkets: IBoardCell[];
  handUnlockCosts: (number | null)[];
  finish: number | null;
  payToLose: boolean;
};

export interface IGameState {
  playerState: IPlayerState;
  lobbyState: {
    handle: string;
    class: string;
    hp: number;
    level: number;
    payToLose: boolean;
  }[];
  opponentIndex: number;
  players: {
    handle: string;
    mmr: number;
    rank: RankString;
    readyToEndRoundEarly: boolean;
  }[];
  roundNumber: number;
  timeExtensions: number;
}

export interface IStoreState {
  balance: {
    gold: number;
    goldDelta: number;
    silver: number;
    silverDelta: number;
  };
  owned: string[];
  forSale: {
    itemName: string;
    itemDisplayName: string;
    goldCost: number;
    silverCost: number;
    description: string;
    icon: string;
  }[];
}

export interface ILobbyState {
  games: {
    //round: number;
    //players: {
    //  handle: string;
    //  hp: number;
    //  finish: number | null;
    //}[];
    count: number;
  };
  lobby: {
    handle: string;
  }[];
}

export type RankString = 'unranked' | 'wood' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond';

export interface IRating {
  mmr: number;
  mmrDelta: number;
  rank: RankString;
  oldRank: RankString;
  gamesPlayed: number;
}

export interface ICharacterClass {
  name: string;
  hp: number;
  text: string;
  tileUnlockCosts: (number | null)[][];
}

// The string part is either 'board-$x-$y' or 'trinket-$i'
export type FightCellId = [number, string];

export type BattleAnimationEvent = (
  {
    kind: 'no-op';
  } | {
    kind: 'card';
    spot: FightCellId;
    card: IBoardCard;
  } | {
    kind: 'pairing';
    playerIndices: number[];
  } | {
    kind: 'set-player';
    playerIndex: number;
    hp: number;
  } | {
    kind: 'fight';
    from: FightCellId;
    to: FightCellId;
    amountToDefender: number;
    amountToAttacker: number;
  } | {
    kind: 'shoot';
    from: FightCellId;
    to: FightCellId;
    amountToDefender: number;
    amountToAttacker: number;
  } | {
    kind: 'player-damage';
    playerIndex: number;
    amount: number;
  } | {
    kind: 'damage';
    spot: FightCellId;
    amount: number;
  } | {
    kind: 'poison';
    spot: FightCellId;
    amount: number;
  } | {
    kind: 'player-text';
    playerIndex: number;
    text: string;
    color: string;
    size: string;
  } | {
    kind: 'center-text';
    text: string;
    color: string;
    size: string;
  } | {
    kind: 'card-text';
    spot: FightCellId;
    text: string;
    color: string;
    size: string;
  }
) & {
  dt: number;
  t: number; // This field isn't actually present in the incoming messages, but we set it.
};

export interface IGameSummary {
  playerHandles: string[];
  playerStates: (IGameState | null)[];
  animations: BattleAnimationEvent[][];
  animationStartTime: number;
}

export type APIMessageFromServer = (
  {
    kind: 'hello';
    now: number;
    version: string;
  } | {
    kind: 'login';
    email: string | null;
    isVerified: boolean;
    accountId: string;
  } | {
    kind: 'email-already-in-use';
    email: string;
  } | {
    kind: 'sign-up-successful';
    email: string;
  } | {
    kind: 'password-reset-sent';
    email: string;
  } | {
    kind: 'password-login';
    email: string;
    token: string | null;
  } | {
    kind: 'all-cards';
    allCards: IBoardCard[];
  } | {
    kind: 'invite-token';
    newInviteToken: string;
  } | {
    kind: 'rating';
    rating: IRating;
    percentiles: string[];
  } | {
    kind: 'leaderboard';
    leaderboard: {
      handle: string;
      mmr: number;
      rank: RankString;
    }[];
  } | {
    kind: 'join-game';
    gameToken: string;
    playerToken: string;
  } | {
    kind: 'bad-game';
    message: string;
  } | {
    kind: 'bad-invite';
    message: string;
  } | {
    kind: 'state';
    timerDeadline: number;
    pregameClassChoices: ICharacterClass[] | null;
    pregameChoice: { index: number; done: boolean };
    state: IGameState;
    animation: BattleAnimationEvent[];
    animationStartTime: number;
  } | {
    kind: 'lobby-state';
    lobbyState: ILobbyState;
  } | {
    kind: 'all-games';
    games: IGameSummary[];
  } | {
  //  kind: 'timer';
  //  seconds: number;
  //} | {
    kind: 'server-sunset';
  } | {
    kind: 'store';
    store: IStoreState;
  } | {
    kind: 'purchase-tokens';
    url: string;
  }
);

export interface IMessageTokens {
  gameToken: string;
  playerToken: string;
}

export type APIMessageActionArgs = (
  {
    kind: 'move-card';
    sourceId: string;
    targetId: string;
  } | {
    kind: 'buy-tile';
    slotId: string;
  } | {
    kind: (
      'reroll' |
      'toggle-lock' |
      'upgrade-rerolls' |
      'upgrade-shop' |
      'upgrade-trinkets' |
      'resign'
    );
  }
);

export type APIMessageToServer = (
  {
    kind: 'login';
    username: string;
    handleAuthoritative: boolean;
    accountToken: string;
    inviteToken: string;
  } | {
    kind: 'sign-up';
    email: string;
    password: string;
  } | {
    kind: 'password-login';
    email: string;
    password: string;
  } | {
    kind: 'reset-password';
    reason: 'reset' | 'sign-up';
    email: string;
  } | {
    kind: 'join-lobby';
    initialPayload: {
      handle: string;
      payToLose: boolean;
    };
  } | {
    kind: 'sunset-check';
  } | (
    {
      kind: 'pregame-choices';
      choices: {
        classIndex: number;
      };
    } & IMessageTokens
  ) | (
    {
      kind: 'get-state' | 'reconnect' | 'end-round-early';
    } & IMessageTokens
  ) | (
    {
      kind: 'action';
      roundNumber?: number;
      action: APIMessageActionArgs;
    } & IMessageTokens
  ) | {
    kind: 'store';
  } | {
    kind: 'fetch-all-games';
  } | {
    kind: 'purchase-tokens';
    sku: string;
  } | {
    kind: 'store-buy';
    itemName: string;
  } | {
    kind: 'ping';
  }
);

export interface IPlayerBattleState {
  hp: number;
  offerings: IBoardCell[];
  board: IBoardCell[][];
  trinkets: IBoardCell[];
}

export function getCellBySlotId(
  playerState: IPlayerBattleState,
  slotId: string,
): IBoardCell | null {
  const parts = slotId.split('-');
  switch (parts[0]) {
    case 'offerings':
      return playerState.offerings[Number(parts[1])];
    case 'trinket':
      return playerState.trinkets[Number(parts[1])];
    case 'board':
      return playerState.board[Number(parts[2])][Number(parts[1])];
    case 'trash':
      return { d: null, cost: null };
  }
  return null;
}

// Yes, I do think client side hashing has a lot of value.
// Upcoming blog post on why this is.
function clientSideHash(x: string): string {
  x = sha256.x2(x);
  x = sha256.x2('autobattler:' + x);
  return x;
}

export class GameConnection {
  refreshCallback: () => void;
  //animationCallback: (animation: BattleAnimationEvent[]) => Promise<void>;
  webSocket: WebSocket | null = null;
  tokens: IMessageTokens | null = null;
  accountToken: string;
  serverVersion = '?';
  // This value corresponds to performance.now() / 1000 - time.monotonic()
  serverTimeOffset: number = 0;
  percentiles = ['', '', '', '', ''];

  // State synced with server.
  allCards: IBoardCard[] = [];
  rating: IRating | null = null;
  timerDeadline: number = 0;
  animation: BattleAnimationEvent[] | null = null;
  animationDuration: number = 0;
  animationStartTime: number = 0;
  pregameClassChoices: ICharacterClass[] | null = null;
  pregameChoice: { index: number; done: boolean } | null = null;
  state: IGameState | null = null;
  lobbyState: ILobbyState | null = null;
  storeState: IStoreState | null = null;
  leaderboard: { handle: string; mmr: number; rank: RankString }[] = [];
  newInviteToken: string | null = null;
  gameSummaries: IGameSummary[] = [];
  accountId: string | null = null;
  accountEmail: string | null = null;
  accountIsVerified: boolean = false;
  signUpMessage: {
    kind: 'email-already-in-use' | 'sign-up-successful' | 'failed-login';
    email: string;
  } | null = null;

  handle: string;
  //fightState: IFightState | null = null;
  //animation: BattleAnimationEvent[] = [];
  reconnectSetTimeoutHandle: any;

  constructor(
    refreshCallback: () => void,
    //animationCallback: (animation: BattleAnimationEvent[]) => Promise<void>,
    handle: string,
  ) {
    this.handle = handle;

    let accountToken = localStorage.getItem(AUTOBATTLER_ACCOUNT_TOKEN_LOCALSTORAGE_KEY);
    if (accountToken === null) {
      accountToken = crypto.randomBytes(16).toString("hex");
      localStorage.setItem(AUTOBATTLER_ACCOUNT_TOKEN_LOCALSTORAGE_KEY, accountToken);
    }
    this.accountToken = accountToken;

    this.refreshCallback = refreshCallback;
    //this.animationCallback = animationCallback;
    this.reconnect();
    // FIXME: No clearing of this.
    setInterval(() => this.send({ kind: 'ping' }), 30000);
  }

  reconnect = () => {
    if (this.webSocket !== null) {
      this.webSocket.removeEventListener('open', this.onOpen);
      this.webSocket.removeEventListener('close', this.onClose);
      this.webSocket.removeEventListener('error', this.onError);
      this.webSocket.removeEventListener('message', this.onMessage);
      this.webSocket.close();
    }
    let suffix = '';
    const previousTokens = localStorage.getItem(AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY);
    if (previousTokens !== null && JSON.parse(previousTokens) !== null)
      suffix = '/' + JSON.parse(previousTokens).gameToken;
    this.webSocket = new WebSocket(
      (window.location.host === 'autobattler.io' ? PROD_WEB_SOCKET_URL : DEV_WEB_SOCKET_URL) + suffix
    );
    this.webSocket.addEventListener('open', this.onOpen);
    this.webSocket.addEventListener('close', this.onClose);
    this.webSocket.addEventListener('error', this.onError);
    this.webSocket.addEventListener('message', this.onMessage);
  }

  onError = () => {
    this.refreshCallback();
    clearTimeout(this.reconnectSetTimeoutHandle);
    this.reconnectSetTimeoutHandle = setTimeout(this.reconnect, 4000 * (1 + Math.random()));
  }

  onClose = () => {
    this.refreshCallback();
    clearTimeout(this.reconnectSetTimeoutHandle);
    this.reconnectSetTimeoutHandle = setTimeout(this.reconnect, 4000 * (1 + Math.random()));
  }

  isConnected(): boolean {
    return this.webSocket !== null && this.webSocket.readyState === WebSocket.OPEN;
  }

  logOut() {
    const accountToken = crypto.randomBytes(16).toString("hex");
    localStorage.setItem(AUTOBATTLER_ACCOUNT_TOKEN_LOCALSTORAGE_KEY, accountToken);
    this.accountToken = accountToken;
    this.tokens = null;
    localStorage.setItem(AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY, JSON.stringify(null));
    this.pregameClassChoices = null;
    this.state = null;
    this.reconnect();
    this.refreshCallback();
  }

  leaveGame() {
    this.tokens = null;
    localStorage.setItem(AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY, JSON.stringify(null));
    this.pregameClassChoices = null;
    this.state = null;
    this.send({ kind: 'sunset-check' });
    this.refreshCallback();
  }

  send(blob: APIMessageToServer) {
    if (this.isConnected())
      this.webSocket!.send(JSON.stringify(blob));
  }

  signUp(email: string, password: string) {
    this.send({
      kind: 'sign-up',
      email,
      password: clientSideHash(password),
    });
  }

  passwordLogin(email: string, password: string) {
    this.send({
      kind: 'password-login',
      email,
      password: clientSideHash(password),
    });
  }

  resetPassword(reason: 'reset' | 'sign-up', email: string) {
    this.send({ kind: 'reset-password', reason, email });
  }

  sendPregameChoices(classIndex: number) {
    this.send({ kind: 'pregame-choices', choices: { classIndex }, ...this.tokens! });
  }

  moveCard(sourceId: string, targetId: string) {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'move-card',
        sourceId,
        targetId,
      },
      ...this.tokens!,
    });
    // Optimistically update the state, so long as no trinkets are involved.
    if (sourceId.startsWith('trinket-') || targetId.startsWith('trinket-'))
      return;
    const sourceCell = getCellBySlotId(this.state!.playerState, sourceId);
    const targetCell = getCellBySlotId(this.state!.playerState, targetId);
    if (sourceCell !== null && targetCell !== null) {
      targetCell.d = sourceCell.d;
      sourceCell.d = null;
    }
    this.refreshCallback();
  }

  reroll() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'reroll',
      },
      ...this.tokens!,
    });
  }

  upgradeRerolls() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'upgrade-rerolls',
      },
      ...this.tokens!,
    });
  }

  upgradeShop() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'upgrade-shop',
      },
      ...this.tokens!,
    });
  }

  upgradeTrinkets() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'upgrade-trinkets',
      },
      ...this.tokens!,
    });
  }

  buyTile(slotId: string) {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'buy-tile',
        slotId,
      },
      ...this.tokens!,
    });
  }

  resign() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'resign',
      },
      ...this.tokens!,
    });
  }

  toggleLock() {
    this.send({
      kind: 'action',
      roundNumber: this.state?.roundNumber,
      action: {
        kind: 'toggle-lock',
      },
      ...this.tokens!,
    });
  }

  readyToEndRoundEarly() {
    this.send({
      kind: 'end-round-early',
      ...this.tokens!,
    });
  }

  getState() {
    // FIXME: You shouldn't ever actually need to call this.
    this.send({
      kind: 'get-state',
      ...this.tokens!,
    });
  }

  getStoreState() {
    this.send({ kind: 'store' });
  }

  purchaseTokens(sku: string) {
    this.send({ kind: 'purchase-tokens', sku });
  }

  buyItem(itemName: string) {
    this.send({ kind: 'store-buy', itemName });
  }

  onOpen = () => {
    this.refreshCallback();
    this.send({
      kind: 'login',
      username: this.handle,
      handleAuthoritative: true,
      accountToken: this.accountToken,
      inviteToken: localStorage.getItem(AUTOBATTLER_INVITE_TOKEN_LOCALSTORAGE_KEY)!,
    });
    this.getStoreState();

    // If we have an existing game try to reconnect.
    const previousTokens = localStorage.getItem(AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY);
    if (previousTokens !== null && JSON.parse(previousTokens) !== null) {
      this.tokens = JSON.parse(previousTokens);
      console.log('Reconnecting to previous game...');
      this.send({
        ...this.tokens!,
        kind: 'reconnect',
      });
    }
  }

  onMessage = (event: MessageEvent<any>) => {
    const apiMessage: APIMessageFromServer = JSON.parse(event.data);
    console.log('Got API message:', apiMessage);
    switch (apiMessage.kind) {
      case 'hello': {
        this.serverVersion = apiMessage.version;
        this.serverTimeOffset = performance.now() / 1000.0 - apiMessage.now;
        //console.log('Server time:', apiMessage.now, 'Our time:', performance.now(), 'Offset:', this.serverTimeOffset);
        this.refreshCallback();
        break;
      }
      case 'login': {
        this.accountEmail = apiMessage.email;
        this.accountIsVerified = apiMessage.isVerified;
        this.accountId = apiMessage.accountId;
        this.refreshCallback();
        break;
      }
      case 'email-already-in-use':
      case 'sign-up-successful': {
        this.signUpMessage = apiMessage;
        this.refreshCallback();
        break;
      }
      case 'password-reset-sent': {
        alert('Password reset email sent to: ' + apiMessage.email);
        break;
      }
      case 'password-login': {
        if (apiMessage.token !== null) {
          localStorage.setItem(AUTOBATTLER_ACCOUNT_TOKEN_LOCALSTORAGE_KEY, apiMessage.token);
          this.accountToken = apiMessage.token;
          this.reconnect();
        } else {
          this.signUpMessage = {
            kind: 'failed-login',
            email: apiMessage.email,
          };
        }
        this.refreshCallback();
        break;
      }
      case 'all-cards': {
        this.allCards = apiMessage.allCards;
        this.refreshCallback();
        break;
      }
      case 'invite-token': {
        this.newInviteToken = apiMessage.newInviteToken;
        this.refreshCallback();
        break;
      }
      case 'rating': {
        this.rating = apiMessage.rating;
        this.percentiles = apiMessage.percentiles;
        this.refreshCallback();
        break;
      }
      case 'leaderboard': {
        this.leaderboard = apiMessage.leaderboard;
        this.refreshCallback();
        break;
      }
      case 'bad-invite': {
        alert('Error: ' + apiMessage.message);
        console.log('Bad invite:', apiMessage.message);
        break;
      }
      case 'bad-game': {
        this.leaveGame();
        console.log('Bad game:', apiMessage.message);
        //alert('Error: ' + apiMessage.message);
        break;
      }
      case 'join-game': {
        this.tokens = {
          gameToken: apiMessage.gameToken,
          playerToken: apiMessage.playerToken,
        };
        localStorage.setItem(AUTOBATTLER_GAME_TOKENS_LOCALSTORAGE_KEY, JSON.stringify(this.tokens));
        this.refreshCallback();
        break;
      }
      case 'state': {
        this.pregameClassChoices = apiMessage.pregameClassChoices;
        this.pregameChoice = apiMessage.pregameChoice;
        this.state = apiMessage.state;
        // Convert the server deadline into an absolute timestamp for our clock.
        this.timerDeadline = apiMessage.timerDeadline + this.serverTimeOffset;
        // Copy the animation over.
        this.animation = apiMessage.animation;
        this.animationDuration = 0;
        // Fill in absolute timestamps on the incoming messages (which won't have them).
        for (const event of this.animation) {
          event.t = this.animationDuration;
          this.animationDuration += event.dt;
        }
        this.animationStartTime = apiMessage.animationStartTime + this.serverTimeOffset;
        this.refreshCallback();
        break;
      }
      case 'lobby-state': {
        this.lobbyState = apiMessage.lobbyState;
        this.refreshCallback();
        break;
      }
      case 'all-games': {
        this.gameSummaries = apiMessage.games;
        for (const summary of this.gameSummaries) {
          for (const f of summary.animations) {
            let animationDuration = 0;
            // Fill in absolute timestamps on the incoming messages (which won't have them).
            for (const event of f) {
              event.t = animationDuration;
              animationDuration += event.dt;
            }
          }
        }
        this.refreshCallback();
        break;
      }
      case 'server-sunset': {
        console.warn('Server is sunsetting!');
        this.reconnect();
        this.refreshCallback();
        break;
      }
      case 'store': {
        this.storeState = apiMessage.store;
        this.refreshCallback();
        break;
      }
      case 'purchase-tokens': {
        // TODO: Is this too dangerous?
        window.location.href = apiMessage.url;
        break;
      }
    }
  }

  joinLobby(handle: string, payToLose: boolean) {
    this.send({
      kind: 'join-lobby',
      initialPayload: {
        handle,
        payToLose,
      },
    });
  }
}
