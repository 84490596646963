import React from 'react';
import { CARD_SIZE, BoardCard, PlayAudio, globalAudio } from './App';
import * as GameConnection from './GameConnection';
import * as Components from './Components';

const DEBUG_LIST_EVENTS = false;

function randomChoice<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

// FIXME: Dedup
const TRINKET_SIZE = (layout: Components.ILayout) => layout.name === 'landscape' ? 100 : 86;

export interface IBattleAnimationProps {
  animation: GameConnection.BattleAnimationEvent[];
  gameState: GameConnection.IGameState;
  layout: Components.ILayout;
  // FIXME: Remove this.
  refreshKey?: number;
}

interface RunningAnimation {
  startTime: number;
  endTime: number;
  callback: (ra: RunningAnimation, lerp: number) => void;
  ref: React.RefObject<any> | null;
  extraDoodad: React.ReactNode;
}

let sequentialCounter = 0;

function getSequential(): string {
  sequentialCounter++;
  return `s${sequentialCounter}`;
}

/*
function PlayerCard(props: {
  gameState: GameConnection.IGameState;
  playerIndex: number;
  hpOverride: number;
}) {
  return (
    <div style={{
      //margin: 10,
    }}>
      <Components.PlayerCard
        gameState={props.gameState}
        playerIndex={props.playerIndex}
        hpOverride={props.hpOverride}
        combatMode
      />
    </div>
  );
}
*/

function makeEmptyPlayerBattleState(): GameConnection.IPlayerBattleState {
  const board: GameConnection.IBoardCell[][] = [];
  for (let y = 0; y < GameConnection.BOARD_ROWS; y++) {
    const row: GameConnection.IBoardCell[] = [];
    board.push(row);
    for (let x = 0; x < GameConnection.BOARD_LENGTH; x++) {
      row.push({ d: null, cost: null });
    }
  }
  const offerings: GameConnection.IBoardCell[] = [];
  const trinkets: GameConnection.IBoardCell[] = [];
  for (let i = 0; i < 6; i++)
    trinkets.push({ d: null, cost: null });
  return {
    hp: 0,
    offerings,
    board,
    trinkets,
  };
}


export class BattleAnimation extends React.PureComponent<IBattleAnimationProps, {
  t: number;
}> {
  innerRef = React.createRef<HTMLSpanElement>();
  boxRef = React.createRef<HTMLDivElement>();
  ourState = makeEmptyPlayerBattleState();
  theirState = makeEmptyPlayerBattleState();
  runningAnimations: RunningAnimation[] = [];
  eventCursor = 0;
  refTable = new Map<string, React.RefObject<HTMLDivElement>>();
  opponentIndex = -1;

  constructor(props: IBattleAnimationProps) {
    super(props);
    console.log('Animation:', props.animation);
    this.state = { t: 0 };
    // Find the pairing event, and use it to determine who our opponent is.
    for (const event of this.props.animation) {
      if (event.kind === 'pairing') {
        for (const playerIndex of event.playerIndices) {
          if (playerIndex !== this.props.gameState.playerState.index) {
            this.opponentIndex = playerIndex;
          }
        }
      }
    }
  }

  getRef(tag: string) {
    if (!this.refTable.has(tag))
      this.refTable.set(tag, React.createRef<HTMLDivElement>());
    return this.refTable.get(tag)!;
  }

  fightCellIdToRef(fightCellId: GameConnection.FightCellId): React.RefObject<HTMLDivElement> {
    const [index, cellName] = fightCellId;
    const tag = `${index === this.props.gameState.playerState.index ? 'our' : 'their'}-${cellName}`;
    console.log('Checking via:', tag);
    return this.getRef(tag);
  }

  fightCellIdToXY(fightCellId: GameConnection.FightCellId): [number, number] {
    //const ref = this.fightCellIdToRef(fightCellId);
    //if (ref.current === null)
    //  return [0, 0];
    //const rect = ref.current.getBoundingClientRect();
    //return [
    //  (rect.left + rect.right) / 2,
    //  (rect.top + rect.bottom) / 2,
    //];

    const [index, cellName] = fightCellId;
    const parts = cellName.split('-');
    if (parts[0] !== 'board') {
      console.error('Bad fightCellIdToXY:', fightCellId);
      return [0, 0];
    }
    let x = Number(parts[1]);
    let y = Number(parts[2]);

    //let [index, [x, y]] = fightCellId;
    let result: [number, number] = [0, 0];
    if (index !== this.props.gameState.playerState.index) {
      x = GameConnection.BOARD_LENGTH - x - 1;
      result[0] += (GameConnection.BOARD_LENGTH * (CARD_SIZE + 5) + 25);
    }
    result[0] += 5 + x * (CARD_SIZE + 5);
    result[1] += 5 + y * (CARD_SIZE + 5);
    result[0] += CARD_SIZE / 2;
    result[1] += CARD_SIZE / 2;
    return result;
  };

  getPlayerCardXY(playerIndex: number): [number, number] {
    const width = 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25;
    const height = 5 + (CARD_SIZE + 5) * GameConnection.BOARD_ROWS;
    const isUs = playerIndex === this.props.gameState.playerState.index;
    if (this.props.layout.name === 'landscape') {
      return [
        isUs ? 100 : width - 100,
        isUs ? height + 75 : -75,
      ];
    }
    return [
      isUs ? -100 : width + 100,
      100,
    ];
  }

  getCenterXY(): [number, number] {
    const width = 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25;
    const height = 5 + (CARD_SIZE + 5) * GameConnection.BOARD_ROWS;
    return [width / 2, height / 2];
  }

  getPlayerState(i: number) {
    return i === this.props.gameState.playerState.index ? this.ourState : this.theirState;
  }

  getCell(spot: GameConnection.FightCellId): GameConnection.IBoardCell | null {
    const [playerIndex, cellName] = spot;
    return GameConnection.getCellBySlotId(this.getPlayerState(playerIndex), cellName);
  }

  newRisingText(startTime: number, duration: number, xy: [number, number], style: React.CSSProperties, child: React.ReactNode) {
    const ref = React.createRef<HTMLDivElement>();
    this.runningAnimations.push({
      startTime,
      endTime: startTime + duration,
      callback: (ra: RunningAnimation, lerp: number) => {
        if (ra.ref!.current === null)
          return;
        ra.ref!.current.style.opacity = Math.pow(1 - lerp, 0.75).toString();
        ra.ref!.current.style.top = `${xy[1] - 100 * lerp}px`;
      },
      ref,
      extraDoodad: (
        <div
          key={getSequential()}
          ref={ref}
          style={{
            textShadow: '-1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black, 1px 1px 2px black',
            zIndex: 100,
            position: 'absolute',
            left: xy[0],
            top: xy[1],
            transform: 'translate(-50%, -50%)',
            ...style,
          }}
        >
          {child}
        </div>
      ),
    });
  }

  applyEvent(event: GameConnection.BattleAnimationEvent, playAudio: boolean) {
    switch (event.kind) {
      case 'no-op': break;
      case 'card': {
        const cell = this.getCell(event.spot);
        if (cell !== null)
          cell.d = event.card;
        else
          console.error('BAD CARD CELL:', event.spot);
        break;
      }
      case 'set-player': {
        const playerState = this.getPlayerState(event.playerIndex);
        playerState.hp = event.hp;
        //playerState = { ...playerState, hp: event.update };
        break;
      }
      case 'fight': {
        globalAudio.play(randomChoice([
          '15-A-melee-attack-1',
          '15-B-melee-attack-2',
          '15-C-melee-attack-3',
        ]));
        const sourceXY = this.fightCellIdToXY(event.from);
        const destXY = this.fightCellIdToXY(event.to);
        this.runningAnimations.push({
          startTime: event.t,
          endTime: event.t + event.dt * 0.8,
          callback: (ra: RunningAnimation, lerp: number) => {
            const cardRef = this.fightCellIdToRef(event.from);
            if (cardRef.current === null)
              return;
            let posLerp = 0;
            if (lerp < 0.25) {
              posLerp = -0.2 * lerp;
            } else if (lerp < 0.5) {
              posLerp = -0.2 * 0.25 + 4 * 1.05 * (lerp - 0.25);
            } else {
              posLerp = 2 - 2 * lerp;
            }
            const x = posLerp * destXY[0] + (1 - posLerp) * sourceXY[0];
            const y = posLerp * destXY[1] + (1 - posLerp) * sourceXY[1];
            cardRef.current.style.left = (x - CARD_SIZE / 2) + 'px';
            cardRef.current.style.top = (y - CARD_SIZE / 2) + 'px';
            cardRef.current.style.zIndex = lerp < 0.99 ? '10' : '0';
          },
          ref: null,
          extraDoodad: null,
        });
        if (event.amountToAttacker > 0) {
          this.newRisingText(
            event.t + event.dt / 2, 1, this.fightCellIdToXY(event.from), { color: 'red', fontSize: '300%' }, `-${event.amountToAttacker}`,
          );
        }
        if (event.amountToDefender > 0) {
          this.newRisingText(
            event.t + event.dt / 2, 1, this.fightCellIdToXY(event.to), { color: 'red', fontSize: '300%' }, `-${event.amountToDefender}`,
          );
        }
        break;
      }
      case 'shoot': {
        globalAudio.play(randomChoice([
          '16-A-ranged-attack-1',
          '16-B-ranged-attack-2',
          '16-C-ranged-attack-3',
        ]));
        const sourceXY = this.fightCellIdToXY(event.from);
        const destXY = this.fightCellIdToXY(event.to);
        this.runningAnimations.push({
          startTime: event.t,
          endTime: event.t + event.dt * 0.4,
          callback: (ra: RunningAnimation, lerp: number) => {
            const cardRef = this.fightCellIdToRef(event.from);
            if (cardRef.current === null)
              return;
            let posLerp = 0.6 * (Math.pow(lerp, 2) - lerp);
            const x = posLerp * destXY[0] + (1 - posLerp) * sourceXY[0];
            const y = posLerp * destXY[1] + (1 - posLerp) * sourceXY[1];
            cardRef.current.style.left = (x - CARD_SIZE / 2) + 'px';
            cardRef.current.style.top = (y - CARD_SIZE / 2) + 'px';
            cardRef.current.style.zIndex = lerp < 0.99 ? '10' : '0';
          },
          ref: null,
          extraDoodad: null,
        });
        const ref = React.createRef<HTMLDivElement>();
        this.runningAnimations.push({
          startTime: event.t + event.dt * 0.3,
          endTime: event.t + event.dt * 0.7,
          callback: (ra: RunningAnimation, lerp: number) => {
            if (ra.ref!.current === null)
              return;
            const x = lerp * destXY[0] + (1 - lerp) * sourceXY[0];
            const y = lerp * destXY[1] + (1 - lerp) * sourceXY[1];
            ra.ref!.current.style.opacity = Math.pow(1 - lerp, 0.3).toString();
            ra.ref!.current.style.left = x + 'px';
            ra.ref!.current.style.top = y + 'px';
          },
          ref,
          extraDoodad: (
            <div
              key={getSequential()}
              ref={ref}
              style={{
                zIndex: 100,
                position: 'absolute',
                width: 50,
                height: 50,
                backgroundColor: 'red',
                border: '2px solid #fcc',
                boxShadow: '0px 0px 10px red',
                borderRadius: '100%',
                left: sourceXY[0],
                top: sourceXY[1],
                transform: 'translate(-50%, -50%)',
              }}
            />
          ),
        });
        if (event.amountToAttacker > 0) {
          this.newRisingText(
            event.t + event.dt / 2, 1, this.fightCellIdToXY(event.from), { color: 'red', fontSize: '300%' }, `-${event.amountToAttacker}`,
          );
        }
        if (event.amountToDefender > 0) {
          this.newRisingText(
            event.t + event.dt / 2, 1, this.fightCellIdToXY(event.to), { color: 'red', fontSize: '300%' }, `-${event.amountToDefender}`,
          );
        }
        break;
      }
      case 'player-damage': {
        globalAudio.play(randomChoice([
          '15-A-melee-attack-1',
          '15-B-melee-attack-2',
          '15-C-melee-attack-3',
        ]));
        this.newRisingText(
          event.t,
          1,
          this.getPlayerCardXY(event.playerIndex),
          {
            color: 'red',
            textShadow: '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black',
            fontSize: '500%',
          },
          `-${event.amount}`,
        );
        break;
      }
      case 'player-text': {
        this.newRisingText(
          event.t,
          1,
          this.getPlayerCardXY(event.playerIndex),
          {
            color: event.color,
            textShadow: '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black',
            fontSize: event.size,
          },
          event.text,
        );
        break;
      }
      case 'center-text': {
        this.newRisingText(
          event.t,
          1,
          this.getCenterXY(),
          {
            color: event.color,
            textShadow: '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black',
            fontSize: event.size,
          },
          event.text,
        );
        break;
      }
      case 'card-text': {
        this.newRisingText(
          event.t,
          1,
          this.fightCellIdToXY(event.spot),
          {
            color: event.color,
            textShadow: '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black',
            fontSize: event.size,
          },
          event.text,
        );
        break;
      }
      case 'damage': {
        this.newRisingText(
          event.t + event.dt / 2, 1, this.fightCellIdToXY(event.spot), { color: 'red', fontSize: '300%' }, `-${event.amount}`,
        );
        break;
      }
      case 'poison': {
        this.newRisingText(
          event.t + event.dt / 2, 1, this.fightCellIdToXY(event.spot), { color: 'green', fontSize: '300%' }, `-${event.amount}`,
        );
        break;
      }
    }
  }

  updateAnimation(t: number) {
    // Now we catch our time cursor up to now.
    while (this.eventCursor < this.props.animation.length && t > this.props.animation[this.eventCursor].t) {
      const age = t - this.props.animation[this.eventCursor].t;
      const playAudio = age < 0.5;
      this.applyEvent(this.props.animation[this.eventCursor++], playAudio);
    }
    // Update all of the individual animations, while filtering out ones that have completed.
    this.runningAnimations = this.runningAnimations.filter((ra) => {
      const lerp = (t - ra.startTime) / (ra.endTime - ra.startTime);
      // We must always call the callback at least once so it can leave things in the appropriate final state.
      ra.callback(ra, Math.max(0, Math.min(lerp, 1)));
      return lerp <= 1;
    });
    this.setState({ t });
  }

  render() {
    // FIXME: We should get this data purely from set-player stuff, not from gameState!
    // Animations should be self-contained!

    const renderTrinkets = (trinkets: GameConnection.IBoardCell[]) => {
      return (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: this.props.layout.name === 'portrait' ? TRINKET_SIZE(this.props.layout) * 2 + 3 * 10 : TRINKET_SIZE(this.props.layout) * 6 + 7 * 10,
          height: this.props.layout.name === 'portrait' ? TRINKET_SIZE(this.props.layout) * 3 + 4 * 10 : TRINKET_SIZE(this.props.layout) * 1 + 2 * 10,
        }}>
          {trinkets.map((trinket, i) =>
            <Components.Trinket
              key={i}
              desc={trinket.d}
              layout={this.props.layout}
              xShift={0}
              //// Shift the tooltip over a little for the leftmost trinket in portrait mode, to keep it on screen.
              //xShift={this.props.layout.name === 'portrait' && i == 0 ? 20 : 0}
            />
          )}
        </div>
      );
    }

    const playerCardOurs = <Components.PlayerCard
      layout={this.props.layout}
      gameState={this.props.gameState}
      playerIndex={this.props.gameState.playerState.index}
      hpOverride={this.ourState.hp}
      combatMode
    />;
    const playerCardTheirs = <Components.PlayerCard
      layout={this.props.layout}
      gameState={this.props.gameState}
      playerIndex={this.opponentIndex}
      hpOverride={this.theirState.hp}
      combatMode
      forceIsOpponent
    />;

    const CARDS_TOP = 0;
    const CARDS_LEFT = 0;

    return (
      <div style={{
        display: 'flex',
        flexDirection: this.props.layout.name === 'landscape' ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
        {/* <PlayAudio name='music-2' fadeInTime={500} fadeOutTime={500} /> */}

        <div style={{
          width: this.props.layout.name === 'landscape' ? 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25 : undefined,
          maxWidth: this.props.layout.width,
          display: 'flex',
          alignItems: 'center',
          flexDirection: this.props.layout.name === 'landscape' ? 'row' : 'column',
          //flexDirection: this.props.layout.name === 'landscape' ? 'column' : 'row',
          //transform: this.props.layout.name === 'landscape' ? undefined : 'translate(0px, 90px)',
        }}>
          <div style={{ flexGrow: 1 }} />

          {this.props.layout.name === 'landscape' ? <>
            {renderTrinkets(this.theirState.trinkets)}
            {playerCardTheirs}
          </> : <>
            {playerCardOurs}
            {renderTrinkets(this.ourState.trinkets)}
          </>}
        </div>

        <div          
          style={{
            border: '1px solid black',
            borderRadius: 10,
            backgroundColor: '#234',
            // We have a margin of 5 around each card, plus a gutter of 30 between the two boards.
            width: 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25,
            minWidth: 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25,
            height: 5 + (CARD_SIZE + 5) * GameConnection.BOARD_ROWS,
            position: 'relative',
            // Scale down to fit if we're in portrait mode.
            //transform: this.props.layout.name === 'landscape' ? undefined : 'scale(0.65)',
          }}
        >
          {this.ourState.board.map((row, y) =>
            <div key={y.toString()} style={{ display: 'flex' }}>
              {row.map((card, x) =>
                <div
                  key={x.toString()}
                  ref={this.getRef(`our-board-${x}-${y}`)}
                  style={{
                    position: 'absolute',
                    left: CARDS_LEFT + 5 + x * (CARD_SIZE + 5),
                    top: CARDS_TOP + 5 + y * (CARD_SIZE + 5),
                  }}
                >
                  <BoardCard desc={card.d} isInBattle />
                </div>
              )}
            </div>
          )}
          {this.theirState.board.map((row, y) =>
            <div key={y.toString()} style={{ display: 'flex' }}>
              {row.map((card, x) =>
                <div
                  key={x.toString()}
                  ref={this.getRef(`their-board-${x}-${y}`)}
                  style={{
                    position: 'absolute',
                    left: CARDS_LEFT + 5 + (GameConnection.BOARD_LENGTH - x - 1) * (CARD_SIZE + 5) + (GameConnection.BOARD_LENGTH * (CARD_SIZE + 5) + 25),
                    top: CARDS_TOP + 5 + y * (CARD_SIZE + 5),
                  }}
                >
                  <BoardCard desc={card.d} isInBattle doMirror />
                </div>
              )}
            </div>
          )}
          {/* Include all doodads from animations that are currently active. */}
          {this.runningAnimations.filter((ra) => this.state.t >= ra.startTime).map((ra) => ra.extraDoodad)}
        </div>

        <div style={{
          width: this.props.layout.name === 'landscape' ? 5 + (CARD_SIZE + 5) * 2 * GameConnection.BOARD_LENGTH + 25 : undefined,
          maxWidth: this.props.layout.width,
          display: 'flex',
          alignItems: 'center',
          flexDirection: this.props.layout.name === 'landscape' ? 'row' : 'column',
          //transform: this.props.layout.name === 'landscape' ? undefined : 'translate(0px, -90px)',
        }}>
          {this.props.layout.name === 'landscape' ? <>
            {playerCardOurs}
            {renderTrinkets(this.ourState.trinkets)}
          </> : <>
            {playerCardTheirs}
            {renderTrinkets(this.theirState.trinkets)}
          </>}

          <div style={{ flexGrow: 1 }} />
        </div>

        {/* For debugging purposes show all events. */}
        {DEBUG_LIST_EVENTS &&
          <div style={{
            position: 'absolute',
            left: 5,
            top: 5,
          }}>
            <span style={{ fontSize: '200%', color: 'red' }}>{this.eventCursor}, {this.state.t}</span>
            {this.props.animation.map((event, index) =>
              <div key={index.toString()}>
                {event.t}: {event.kind}
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}
