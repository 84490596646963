import React, { Children } from 'react';
import * as GameConnection from './GameConnection';

export const ppText: React.CSSProperties = {
  fontWeight: 'bold',
}

export const goldText: React.CSSProperties = {
  color: 'white',
  textShadow: '-1px -1px 2px black, 1px 1px 2px black, -1px 1px 2px black, 1px -1px 2px black',
};

export const levelText: React.CSSProperties = {
  color: 'white',// '#33f',
  textShadow: '-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
};

export interface ILayout {
  width: number;
  height: number;
  name: string;
}

export function RotationBox(props: {
  width: number;
  height: number;
  children: React.ReactNode;
  ccw?: boolean;
}) {
  return (
    <div style={{
      width: props.width,
      minWidth: props.width,
      maxWidth: props.width,
      height: props.height,
      minHeight: props.height,
      maxHeight: props.height,
    }}>
      <div style={{
        // Intentionally swapped width and height here!
        width: props.height,
        minWidth: props.height,
        maxWidth: props.height,
        height: props.width,
        minHeight: props.width,
        maxHeight: props.width,
        transform: props.ccw ?
            'translate(-50%, -50%) rotate(-90deg) translate(-50%, 50%)'
          : 'translate(-50%, -50%) rotate(90deg) translate(50%, -50%)',
        position: 'relative',
      }}>
        {props.children}
      </div>
    </div>
  )
}


export const RescalerContext = React.createContext<Rescaler | null>(null);

export interface IRescalerProps {
  layouts: ILayout[];
  children: (layout: ILayout) => React.ReactNode;
  onDragEnd: (drop: IDrop) => void;
}

export class Rescaler extends React.Component<IRescalerProps, {
  targetScale: number;
  centeringOffset: number;
  layout: ILayout;
}> {
  dragState: {
    sourceSlotId: string;
    div: HTMLDivElement;
    offsetX: number;
    offsetY: number;
    dragWidth: number;
    dragHeight: number;
    dropCallback: () => void;
    //defocusCallback: () => void;
  } | null = null;
  highlitSlot: DragSlot | null = null;
  lastCoords = [0, 0];
  containerRef = React.createRef<HTMLDivElement>();
  contentRef = React.createRef<HTMLDivElement>();
  slots = new Set<DragSlot>();

  constructor(props: IRescalerProps) {
    super(props);
    this.state = {
      targetScale: 1,
      centeringOffset: 0,
      layout: this.props.layouts[0],
    };
  }

  registerSlot(slot: DragSlot) {
    this.slots.add(slot);
  }

  unregisterSlot(slot: DragSlot) {
    this.slots.delete(slot);
  }

  recompute = () => {
    if (this.containerRef.current !== null && this.contentRef.current !== null) {
      const containerRect = this.containerRef.current.getBoundingClientRect();
      //const contentRect = this.contentRef.current.getBoundingClientRect();
      // Find the best layout.
      let bestTargetScale = -1;
      for (const layout of this.props.layouts) {
        const targetScale = Math.min(
          containerRect.width / layout.width,
          containerRect.height / layout.height,
        );
        if (targetScale > bestTargetScale) {
          bestTargetScale = targetScale;
          const centeringOffset = (containerRect.width - layout.width * targetScale) / 2;
          this.setState({ targetScale, centeringOffset, layout });
        }
      }
    }
  }

  componentDidMount() {
    this.recompute();
    window.addEventListener('resize', this.recompute);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('click', this.onClick);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recompute);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('click', this.onClick);
  }

  onMouseMove = (event: MouseEvent) => {
    this.lastCoords = [event.clientX, event.clientY];
    this.applyCoords();
  }

  applyCoords() {
    if (this.dragState !== null) {
      this.dragState.div.style.left = (this.lastCoords[0] + this.dragState.offsetX - this.state.centeringOffset) / this.state.targetScale + 'px';
      this.dragState.div.style.top = (this.lastCoords[1] + this.dragState.offsetY) / this.state.targetScale + 'px';
    }
  }

  completeDrag(sourceSlotId: string, destSlotId: string) {
    this.props.onDragEnd({
      source: {
        slotId: sourceSlotId,
      },
      destination: {
        slotId: destSlotId,
      },
    });
    //if (this.dragState !== null) {
    //  this.dragState.dropCallback();
    //  this.dragState = null;
    //}
  }

  // If somebody mouses up globally then stop any dragging.
  onClick = (event: MouseEvent) => {
    // Ugh, this is so absurdly hacky, but it should help.
    this.defocus();
    //setTimeout(this.defocus, 10);
    if (this.dragState === null)
      return;

    // Try to find if this was a valid drop into any slot.
    let dropSlot: DragSlot | null = null;
    for (const slot of this.slots) {
      // Don't process drops from a tile onto itself.
      if (slot.props.slotId === this.dragState.sourceSlotId)
        continue;
      const rect = slot.getBoundingClientRect();
      if (rect === null)
        continue;
      // FIXME: Does this interact properly with the rescaler?
      const draggedObjectCenter = [
        event.clientX + this.dragState.offsetX + this.dragState.dragWidth / 2,
        event.clientY + this.dragState.offsetY + this.dragState.dragHeight / 2,
      ];
      if (
        rect.left <= draggedObjectCenter[0] &&
        draggedObjectCenter[0] <= rect.right &&
        rect.top <= draggedObjectCenter[1] &&
        draggedObjectCenter[1] <= rect.bottom
      ) {
        this.completeDrag(this.dragState.sourceSlotId, slot.props.slotId);
      }
    }

    this.dragState.dropCallback();
    this.dragState = null;
    //if (--this.dragState.clickdownCounter <= 0) {
    //  this.dragState.defocusCallback();
    //  this.dragState = null;
    //}
    this.forceUpdate();
  }

  defocus = () => {
    if (this.highlitSlot !== null) {
      const save = this.highlitSlot;
      this.highlitSlot = null;
      save.forceUpdate();
    }
  }

  render() {
    return (
      <RescalerContext.Provider value={this}>
        <div
          ref={this.containerRef}
          style={{
            width: '100%',
            height: '100vh',
          }}
        >
          <div
            ref={this.contentRef}
            style={{
              display: 'inline-block',
              transform: `
                translate(${this.state.centeringOffset}px, 0px)
                translate(-50%, -50%)
                scale(${this.state.targetScale})
                translate(50%, 50%)
              `,
            }}
          >
            <div style={{
              width: this.state.layout.width,
              minWidth: this.state.layout.width,
              maxWidth: this.state.layout.width,
              height: this.state.layout.height,
              minHeight: this.state.layout.height,
              maxHeight: this.state.layout.height,
              //backgroundColor: this.state.layout.name === 'landscape' ? 'white' : 'yellow',
              position: 'relative',
            }}>
              {this.props.children(this.state.layout)}
            </div>
          </div>
        </div>
      </RescalerContext.Provider>
    );
  }
}

// ===== Drag and drop =====

export interface IDrop {
  source: {
    slotId: string;
  };
  destination: {
    slotId: string;
  };
}

export interface IDragSlotProps {
  slotId: string;
  disableDrag?: boolean;
  disableDrop?: boolean;
  children: (isHighlit: boolean) => React.ReactNode;
}

export class DragSlot extends React.PureComponent<IDragSlotProps, {
  drag: boolean;
}> {
  static contextType = RescalerContext;
  draggableDivRef = React.createRef<HTMLDivElement>();
  slotContainerRef = React.createRef<HTMLDivElement>();
  state = { drag: false };

  componentDidMount() {
    this.context.registerSlot(this);
  }

  componentWillUnmount() {
    this.context.unregisterSlot(this);
  }

  getBoundingClientRect(): DOMRect | null {
    if (this.slotContainerRef.current === null)
      return null;
    const rect = this.slotContainerRef.current.getBoundingClientRect();;
    return rect;
  }

  onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.disableDrag)
      return;
    if (this.slotContainerRef.current === null)
      return;
    const rect = this.slotContainerRef.current.getBoundingClientRect();
    this.context.dragState = {
      sourceSlotId: this.props.slotId,
      div: this.draggableDivRef.current,
      offsetX: (rect.left - event.clientX),
      offsetY: (rect.top - event.clientY),
      dragWidth: rect.width,
      dragHeight: rect.height,
      dropCallback: () => {
        this.setState({ drag: false });
      },
    };
    this.context.forceUpdate();
    this.setState({ drag: true }, () => this.context.applyCoords());
  }

  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const rescaler = this.context as Rescaler;
    if (!this.props.disableDrag && rescaler.highlitSlot === null) {
      rescaler.highlitSlot = this;
      if (rescaler.dragState !== null)
        rescaler.dragState.dropCallback();
      rescaler.dragState = null;
      this.forceUpdate();
      event.stopPropagation();
    } else if (!this.props.disableDrop && rescaler.highlitSlot !== null && rescaler.highlitSlot !== this) {
      rescaler.completeDrag(rescaler.highlitSlot.props.slotId, this.props.slotId);
    }
  }

  render() {
    const rescaler = this.context as Rescaler;
    return <>
      <div style={{
        boxShadow: rescaler.highlitSlot === this ? '0px 0px 30px yellow' : '0px 0px 0px yellow',
        transition: 'box-shadow 0.3s',
      }}>
        <div
          style={{
            //outline: this.state.drag ? '3px solid green' : undefined,
            opacity: this.state.drag ? 0 : 1,
            cursor: 'grab',
          }}
          ref={this.slotContainerRef}
          onMouseDown={this.onMouseDown}
          onClick={this.onClick}
        >
          {this.props.children(rescaler.highlitSlot === this)}
        </div>
      </div>

      <div
        style={{
          //outline: '10px solid blue',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 20,
          pointerEvents: 'none',
        }}
        ref={this.draggableDivRef}
      >
        {this.state.drag && this.props.children(false)}
      </div>
    </>;
  }
}

export type StatName = 'poison' | 'atk' | 'def' | 'stun' | 'shields' | 'counter';
export type IconName = StatName | 'coin' | 'unrated' | 'wood' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'trash' | 'check' | 'plate';

export function Icon(props: { icon: IconName, value?: React.ReactNode, style?: React.CSSProperties, scale?: number }) {
  const scale = props.scale === undefined ? 1 : props.scale;
  if (props.icon === 'coin') {
    return (
      <GoldCoin
        width={40 * scale}
        amount={props.value}
        style={props.style}
      />
    );
  }
  let backgroundImage = ({
    poison: 'url("/assets/poison.png")',
    atk: 'url("/assets/attack.png")',
    def: 'url("/assets/heart.png")',
    stun: 'url("/assets/sleep.png")',
    shields: 'url("/assets/shield2.png")',
    counter: 'url("/assets/magic.png")',
    unrated: 'url("/assets/unrated2.png")',
    wood: 'url("/assets/01_wood.png")',
    bronze: 'url("/assets/02_bronze.png")',
    silver: 'url("/assets/03_silver.png")',
    gold: 'url("/assets/04_gold.png")',
    platinum: 'url("/assets/05_platinum.png")',
    diamond: 'url("/assets/06_diamond_crop.png")',
    trash: 'url("/assets/trash.png")',
    check: 'url("/assets/check-mark.png")',
    plate: 'url("/assets/stone-plate-large.png")',
  } as { [key in IconName]: string })[props.icon];

  return (
    <div style={{
      width: 40 * scale,
      height: 40 * scale,
      backgroundImage,
      backgroundSize: '100%',
      display: 'inline-flex',
      verticalAlign: 'middle',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: (22 * scale) + 'px',
      color: 'white',
      textShadow: '1px 1px 0px black, -1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black',
      ...props.style,
    }}>
      {props.value}
    </div>
  );
}

export function renderRank(rank: GameConnection.RankString, scale: number) {
  switch (rank) {
    case 'unranked': return <Icon scale={scale} icon='unrated' />;
    case 'wood': return <Icon scale={scale} icon='wood' />;
    case 'bronze': return <Icon scale={scale} icon='bronze' />;
    case 'silver': return <Icon scale={scale} icon='silver' />;
    case 'gold': return <Icon scale={scale} icon='gold' />;
    case 'platinum': return <Icon scale={scale} icon='platinum' />;
    case 'diamond': return <Icon scale={scale} icon='diamond' />;
  }
}

export function PlayerCard(props: {
  layout: ILayout;
  gameState: GameConnection.IGameState;
  playerIndex: number;
  hpOverride?: number;
  combatMode?: boolean;
  forceIsOpponent?: boolean;
}) {
  const playerData = props.gameState.lobbyState[props.playerIndex];
  if (playerData === undefined)
    return <div key={props.playerIndex}>BUG: Player not found</div>;
  const hp = props.hpOverride !== undefined ? props.hpOverride : playerData.hp;
  const rank = props.gameState.players[props.playerIndex].rank;
  const landscape = props.layout.name === 'landscape';
  let filter: string | undefined = undefined;
  if (props.playerIndex === props.gameState.playerState.index) {
    filter = 'sepia(100%) saturate(150%) brightness(70%) hue-rotate(90deg)';
  }
  if (props.playerIndex === props.gameState.opponentIndex || props.forceIsOpponent) {
    filter = 'sepia(100%) saturate(150%) brightness(70%) hue-rotate(300deg)';
  }
  const width = landscape ? 200 : 175;
  const height = landscape ? 118 : 103;
  return (
    <div key={props.playerIndex} style={{
      //border: playerData.payToLose ? '3px solid gold' : '1px solid black',
      //borderRadius: 5,
      //boxSizing: 'border-box',

      //backgroundColor: props.playerIndex === props.gameState.playerState.index ? '#9c9' : (
      //  props.playerIndex === props.gameState.opponentIndex ? '#c99' : '#999'
      //),
      backgroundImage: `url("/assets/stone-plate-small-crop.png")`,
      backgroundSize: '100% 100%',
      width,
      height,
      minWidth: width,
      minHeight: height,
      maxWidth: width,
      maxHeight: height,
      position: 'relative',
      opacity: hp > 0 ? 1 : 0.5,
      color: hp > 0 ? 'white' : 'black',
      textShadow: hp > 0 ? '1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black, -1px -1px 1px black' : undefined,
    }}>
      <div style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        filter,
        backgroundImage: `url("/assets/metal-border-small.png")`,
        backgroundSize: '100% 100%',
      }} />
      <div style={{ position: 'absolute', top: landscape ? 20 : 14, left: 25, fontSize: landscape ? '100%' : '80%' }}>
        {playerData.handle}
      </div>
      <div style={{ position: 'absolute', bottom: landscape ? 20 : 16, left: 25, fontSize: landscape ? '100%' : '90%' }}>
        {playerData.class}
      </div>
      {rank !== 'unranked' &&
        <div style={{
          position: 'absolute',
          bottom: landscape ? 23 : 15,
          right: landscape ? 26 : 27,
          textAlign: 'center',
          fontSize: '80%',
        }}>
          {props.gameState.players[props.playerIndex].mmr}
        </div>
      }
      <div style={{ position: 'absolute', bottom: '52%', right: 15, transform: 'translate(0px, 50%)' }}>
        {/*renderRank(['wood', 'bronze', 'silver', 'gold', 'platinum', 'diamond'][Math.floor(Math.random() * 6)], 1)*/}
        {renderRank(rank, 1.2)}
      </div>
      {props.gameState.players[props.playerIndex].readyToEndRoundEarly && props.combatMode !== true &&
        <Icon
          icon='check'
          scale={1.5}
          style={{
            position: 'absolute',
            right: -5,
            top: -10,
            zIndex: 2,
          }}
        />
      }
      <div style={{
        fontSize: '200%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        {hp > 0 ? hp : '💀'}
      </div>
    </div>
  );
}

export function GoldCoin(props: {
  width: number;
  amount?: React.ReactNode;
  disable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onClickWhenDisabled?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        width: props.width,
        height: props.width,
        backgroundImage: `url("/assets/gold-coin-lowsat.png")`,
        backgroundSize: '100%',
        fontSize: (0.4 * props.width) + 'px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        cursor: (props.disable || props.onClick === undefined) ? undefined : 'pointer',
        opacity: props.disable ? 0.6 : 1,
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <span
        style={{
          ...goldText,
          transform: `translate(${-props.width / 50}px, 0px)`,
        }}
      >{props.amount}</span>
    </div>
  );
}

type ButtonIcon = 'lock' | 'reroll' | 'upgrade-rerolls' | 'upgrade-critters' | 'upgrade-magic' | 'check' | 'fullscreen';

export function SpecialButton(props: {
  scale: number;
  children?: React.ReactNode;
  icon?: ButtonIcon;
  vertical?: boolean;
  disable?: boolean;
  style?: React.CSSProperties;
  gold?: number | null;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onClickWhenDisabled?: React.MouseEventHandler<HTMLDivElement>;
}) {
  let backgroundImage = props.icon === undefined ? undefined : ({
    lock: 'url("/assets/lock.png")',
    reroll: 'url("/assets/reroll.png")',
    'upgrade-rerolls': 'url("/assets/upgrade-rerolls.png")',
    'upgrade-critters': 'url("/assets/upgrade-critter-level.png")',
    'upgrade-magic': 'url("/assets/upgrade-magic-level.png")',
    check: 'url("/assets/check-mark.png")',
    fullscreen: 'url("/assets/fullscreen.png")',
  } as { [key in ButtonIcon]: string })[props.icon];
  return (
    <div
      style={{
        ...(
          props.vertical ? {
            width: 300 * props.scale,
            height: 400 * props.scale,
            backgroundImage: 'url("/assets/button-vert-lowsat.png")',
          } : {
            width: 400 * props.scale,
            height: 300 * props.scale,
            backgroundImage: 'url("/assets/button-horiz-lowsat.png")',
          }
        ),
        backgroundSize: '100%',
        userSelect: 'none',
        cursor: props.disable ? undefined : 'pointer',
        opacity: props.disable ? 0.6 : 1,
        ...props.style,
      }}
      onClick={(event) => {
        if (props.disable) {
          if (props.onClickWhenDisabled !== undefined)
            return props.onClickWhenDisabled(event);
        } else if (props.onClick !== undefined) {
          return props.onClick(event);
        }
      }}
    >
      <div style={{ position: 'relative', height: '100%' }}>
        {props.icon === undefined ?
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '120%',
            color: 'white',
            textShadow: '-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
          }}>
            {props.children}
          </div>
        :
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            width: 230 * props.scale,
            height: 230 * props.scale,
            backgroundImage,
            backgroundSize: '100%',
            transform: 'translate(-50%, -50%)',
          }}/>
        }

        {props.gold != null &&
          <GoldCoin
            width={120 * props.scale}
            style={{
              position: 'absolute',
              right: -10 * props.scale,
              top: -10 * props.scale,
            }}
            amount={props.gold}
          />
        }
      </div>
    </div>
  );
}

export const hoverListeners = new Set<any>();

export function clearAllHover() {
  for (const listener of hoverListeners) {
    listener.setState({ hover: false });
  }
}

export function renderCardText(text: string): React.ReactNode {
  return text;
}

export const TRINKET_SIZE = (layout: ILayout) => layout.name === 'landscape' ? 100 : 86;

export interface ITrinketProps {
  desc: GameConnection.IBoardCard | null;
  layout: ILayout;
  xShift: number;
  forceTooltip?: boolean;
}

export class Trinket extends React.Component<ITrinketProps, {
  hover: boolean;
}> {
  constructor(props: ITrinketProps) {
    super(props);
    this.state = { hover: false };
  }

  componentDidMount() {
    hoverListeners.add(this);
  }

  componentWillUnmount() {
    hoverListeners.delete(this);
  }

  render() {
    const shift = this.props.desc === null ? 5 : 0;
    return (
      <div
        style={{
          margin: 5 + shift,
          boxSizing: 'border-box',
          width: TRINKET_SIZE(this.props.layout) - 2 * shift,
          height: TRINKET_SIZE(this.props.layout) - 2 * shift,
          border: '1px solid black',
          borderRadius: 5 + shift,
          backgroundColor: this.props.desc === null ? '#444' : '#898',
          backgroundImage: this.props.desc?.img == null ? undefined : `url("/assets/${this.props.desc.img}")`,
          backgroundSize: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          userSelect: 'none',
          position: 'relative',
          fontSize: '120%',
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={() => {
          clearAllHover();
          if (!this.state.hover)
            this.setState({ hover: true });
        }}
      >
        {this.props.desc !== null && this.props.desc.img === undefined && this.props.desc.c}

        {this.state.hover && <div style={{
          position: 'absolute',
          top: 5,
          left: 5,
          ...levelText,
        }}>
          {this.props.desc !== null && this.props.desc.level}
        </div>}
        {this.state.hover && <div style={{
          position: 'absolute',
          top: 5,
          right: 5,
          ...goldText,
        }}>
          {this.props.desc !== null && this.props.desc.gold}
        </div>}

        {this.props.desc !== null && this.props.desc.text !== '' && (this.state.hover || this.props.forceTooltip) && <div style={{
          position: 'absolute',
          zIndex: 105,
          width: 150,
          // TODO: Decide whether to be above or below the card.
          top: TRINKET_SIZE(this.props.layout) + 5,
          padding: 5,
          left: 0.5 * TRINKET_SIZE(this.props.layout) + this.props.xShift,
          transform: 'translate(-50%, 0)',
          border: '1px solid black',
          backgroundColor: '#aaa',
          fontSize: '24px',
        }}>
          {renderCardText(this.props.desc.text)}
        </div>}
      </div>
    );
  }
}