import React from 'react';
import * as GameConnection from './GameConnection';
import * as Components from './Components';
import * as Animation from './Animation';
import { BrowserRouter as Router, Route, Switch, Link, useHistory } from 'react-router-dom';
import { Howl, Howler } from 'howler';
import audioAtlas from './audio-atlas.json';
//import * as HistoryModule from 'history';
import './App.css';

export const globalAudio = new Howl({
  src: ['/assets/audio-map.webm', '/assets/audio-map.mp3'],
  sprite: audioAtlas as unknown as { [soundName: string]: [number, number, boolean] },
});

const VERSION_STRING = 'v0.0.51';
const HANDLE_LOCALSTORAGE_KEY = 'autobattlerHandle';
const PAY_TO_LOSE_LOCALSTORAGE_KEY = 'autobattlerPayToLoseChecked';
const ENABLE_STORE = false;
const ENABLE_CREDITS = true;
//const CARD_SIZE = (layout: Components.ILayout) => layout.name === 'landscape' ? 175 : 150;
export const CARD_SIZE = 165;
export const TRINKET_SIZE = 90;
const TOP_BAR_HEIGHT = 69;

const blackBorderTextShadow = '-1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black, 1px 1px 0px black';

const PLACEMENT_GAMES = 4;
const PLACEMENT_GAMES_ENGLISH = 'Four';
const DEBUG_STAY_ON_ANIMATION = false;

function isValidHandle(handle: string) {
  return 0 < handle.length && handle.length <= 30;
}

export class PlayAudio extends React.PureComponent<{
  name: string;
  fadeInTime: number;
  fadeOutTime: number;
}> {
  handle: number | null = null;

  componentDidMount() {
    this.handle = globalAudio.play(this.props.name);
    globalAudio.fade(0, 1, this.props.fadeInTime, this.handle);
  }

  componentWillUnmount() {
    if (this.handle !== null) {
      const handle = this.handle;
      globalAudio.fade(1, 0, this.props.fadeOutTime, handle);
      setTimeout(() => globalAudio.stop(handle), this.props.fadeOutTime);
    }
  }

  render() {
    return null;
  }
}

export interface IBoardCardProps {
  desc: GameConnection.IBoardCard | null;
  isInBattle?: boolean;
  showGoldCost?: boolean;
  opacity?: number;
  tooltipAbove?: boolean;
  doMirror?: boolean;
  forceTooltip?: boolean;
  isTrinketSlot?: boolean;
}

export class BoardCard extends React.Component<IBoardCardProps, {
  hover: boolean;
}> {
  constructor(props: IBoardCardProps) {
    super(props);
    this.state = { hover: false };
  }

  componentDidMount() {
    Components.hoverListeners.add(this);
  }

  componentWillUnmount() {
    Components.hoverListeners.delete(this);
  }

  render() {
    const isTrinket: boolean = this.props.desc?.isTrinket === true;
    const isSpell: boolean = this.props.desc?.isSpell === true;
    const isCritter = !isTrinket && !isSpell;
    let backgroundColor = '#444';
    let filter: string | undefined = undefined;
    if (this.props.desc !== null) {
      backgroundColor = '#999';
    }
    if (isTrinket) {
      backgroundColor = '#898';
      filter = 'sepia(100%) saturate(150%) brightness(70%) hue-rotate(90deg)';
    }
    if (isSpell) {
      backgroundColor = '#988';
      filter = 'sepia(100%) saturate(150%) brightness(70%) hue-rotate(300deg)';
    }

    let statuses: React.ReactNode[] = [];
    let statusBottom = 40;
    if (this.props.desc !== null && isCritter) {
      const names: Components.StatName[] = ['poison', 'stun', 'shields', 'counter'];
      for (const name of names) {
        if (this.props.desc[name] !== undefined) {
          let icon: Components.IconName = name;
          if (name === 'counter' && this.props.desc.c === 'Bank')
            icon = 'coin';
          statuses.push(
            <Components.Icon
              style={{
                position: 'absolute',
                bottom: statusBottom,
                right: 0,
                zIndex: 2,
                pointerEvents: 'none',
                opacity: this.props.opacity,
              }}
              key={statusBottom}
              icon={icon}
              value={this.props.desc[name]!}
            />
          );
          statusBottom += 40;
        }
      }
    }

    const showSpecial = this.props.desc !== null && (this.props.showGoldCost || this.state.hover);

    return (
      <div style={{ position: 'relative', userSelect: 'none', color: 'black' }}>
        {/* Show the main card */}
        <div
          style={{
            width: this.props.isTrinketSlot ? TRINKET_SIZE : CARD_SIZE,
            height: this.props.isTrinketSlot ? TRINKET_SIZE : CARD_SIZE,
            //boxSizing: 'border-box',
            //border: '1px solid black',
            //border: '1px solid black',
            //borderRadius: 5,
            opacity: this.props.opacity,
            position: 'relative',
          }}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={() => {
            Components.clearAllHover();
            if (!this.state.hover)
              this.setState({ hover: true });
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '4%',
              left: '4%',
              width: '92%',
              height: '92%',
              backgroundImage: this.props.desc?.img == null ? undefined : `url("/assets/${this.props.desc.img}")`,
              backgroundSize: '100%',
              backgroundColor,
              transform: this.props.doMirror ? 'scaleX(-1)' : undefined,
              zIndex: 0,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: this.props.desc === null ? 'url("/assets/stone-plate-large-crop.png")'
                : (showSpecial ? 'url("/assets/metal-border-large-corner2.png")' : 'url("/assets/metal-border-large.png")'),
              backgroundSize: '100%',
              filter,
              zIndex: 1,
            }}
          />
        </div>

        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          textAlign: 'center',
          fontSize: '130%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          opacity: this.props.opacity,
          zIndex: 2,
        }}>
          {this.props.desc !== null && this.props.desc.img === undefined && this.props.desc.c}
        </div>

        {showSpecial && <div style={{
          position: 'absolute',
          top: 4,
          left: 7,
          pointerEvents: 'none',
          opacity: this.props.opacity,
          zIndex: 2,
          ...Components.levelText,
        }}>
          {this.props.desc!.level}
        </div>}

        {showSpecial &&
          <Components.Icon
            icon='coin'
            value={this.props.desc!.gold}
            style={{
              position: 'absolute',
              top: 2,
              right: 2,
              zIndex: 2,
              opacity: this.props.opacity,
            }}
          />
        }
        {this.props.desc !== null && this.props.desc.atk > 0 && isCritter &&
          <Components.Icon
            icon={this.props.desc.dealsPoison === true ? 'poison' : 'atk'}
            value={this.props.desc.atk}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 2,
              opacity: this.props.opacity,
            }}
          />
        }
        {this.props.desc !== null && isCritter &&
          <Components.Icon
            icon='def'
            value={this.props.isInBattle ? this.props.desc.hp : this.props.desc.def}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 2,
              opacity: this.props.opacity,
            }}
          />
        }

        {statuses}

        {/* Show the tooltip */}
        {this.props.desc !== null && this.props.desc.text !== '' && (this.state.hover || this.props.forceTooltip) && <div style={{
          position: 'absolute',
          zIndex: 105,
          width: CARD_SIZE * 1.1,
          // TODO: Decide whether to be above or below the card.
          top: this.props.tooltipAbove ? undefined : CARD_SIZE + 5,
          bottom: this.props.tooltipAbove ? CARD_SIZE + 5 : undefined,
          padding: 5,
          left: 0.5 * CARD_SIZE,
          transform: 'translate(-50%, 0)',
          border: '1px solid black',
          backgroundImage: 'url("/assets/noise2-light.png")',
          fontSize: '24px',
        }}>
          {Components.renderCardText(this.props.desc.text)}
        </div>}
      </div>
    );
  }
}

function CardCell(props: {
  dropId: string;
  desc: GameConnection.IBoardCell;
  disableDrag?: boolean;
  disableDrop?: boolean;
  showGoldCost?: boolean;
  canBuyTile?: boolean;
  hideSlot?: boolean;
  tooltipAbove?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <div
      style={{
        margin: 5,
        //padding: 2,
        width: CARD_SIZE,
        height: CARD_SIZE,
        boxSizing: 'border-box',
        //border: props.hideSlot ? undefined : '1px solid rgba(0, 0, 0, 0.2)',
        border: props.desc.cost === null ? undefined : '3px solid rgba(0, 0, 0, 0.2)',
        borderStyle: props.desc.cost === null ? undefined : 'dashed',
        borderRadius: 5,
        backgroundColor: props.hideSlot ? undefined : (props.desc.cost === null ? '#444' : '#333'),
        //(snapshot.isDraggingOver ? '#ccf' : '#ccc'),
        //opacity: props.disableDrag ? 0.7 : 1,
        transition: 'background-color 0.2s, opacity 0.2s',
      }}
    >
      {props.desc.cost !== null ?
        <Components.GoldCoin
          width={50}
          amount={props.desc.cost >= 1e6 ? '∞' : props.desc.cost}
          disable={!props.canBuyTile}
          onClick={props.onClick}
          style={{
            margin: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      :
        <Components.DragSlot
          slotId={props.dropId}
          disableDrag={props.disableDrag || props.desc.d === null}
          disableDrop={props.disableDrop}
        >
          {(isHighlit: boolean) =>
            <BoardCard
              desc={props.desc.d}
              showGoldCost={props.showGoldCost}
              tooltipAbove={props.tooltipAbove}
              forceTooltip={isHighlit}
              opacity={props.disableDrag ? 0.6 : 1}
            />
          }
        </Components.DragSlot>
      }
    </div>
  );
}

interface ITimerBarProps {
  timerDeadline: number | null;
  fadeInAt?: number;
}

class TimerBar extends React.PureComponent<ITimerBarProps> {
  intervalHandle: any;
  rafHandle: any;
  barRef = React.createRef<HTMLDivElement>();
  textRef = React.createRef<HTMLDivElement>();

  constructor(props: ITimerBarProps) {
    super(props);
    this.state = {};
  }

  rafLoop = (time: number) => {
    this.rafHandle = window.requestAnimationFrame(this.rafLoop);
    if (this.barRef.current === null || this.textRef.current === null)
      return;
    if (this.props.timerDeadline === null) {
      this.barRef.current.style.opacity = '0';
      this.textRef.current.style.opacity = '0';
      return;
    }
    this.barRef.current.style.opacity = '1';
    this.textRef.current.style.opacity = '1';
    const now = performance.now() / 1000;
    const remaining = Math.max(0, this.props.timerDeadline - now);

    // Lerp is 0 at the start of the rush period and 1 when you run out of time.
    const RUSH_PERIOD = 15;
    const lerp = Math.max(0, 1 - (remaining / RUSH_PERIOD));

    this.barRef.current.style.width = (1 - lerp) * 530 + 'px';
    const r = Math.pow(lerp, 2) * 8 * Math.sin(Math.pow(lerp, 2) * 175);
    const fadeInTime = 2;
    const opacity = (
      this.props.fadeInAt === undefined ? 1 :
      Math.max(0, Math.min(1, (this.props.fadeInAt + fadeInTime - remaining) / fadeInTime))
    ).toString();
    this.barRef.current.style.transform = `rotate(${r}deg)`;
    this.barRef.current.style.backgroundColor = `rgb(${255 * lerp}, ${200 * (1 - lerp*lerp)}, ${200 * (1 - lerp)})`;
    this.barRef.current.style.opacity = opacity;
    const bonusSize = Math.pow(lerp, 2) * 100 * Math.pow(Math.sin(lerp * RUSH_PERIOD * Math.PI), 2);
    this.textRef.current.style.fontSize = (250 + bonusSize) + '%';
    this.textRef.current.innerText = Math.round(remaining) + 's';
    this.textRef.current.style.opacity = opacity;
  }

  componentDidMount() {
    this.rafHandle = window.requestAnimationFrame(this.rafLoop);
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
    window.cancelAnimationFrame(this.rafHandle);
  }

  render() {
    return (
      <div style={{ margin: 10, paddingTop: 5, paddingBottom: 5, position: 'relative' }}>
        <div
          ref={this.barRef}
          style={{
            border: '1px solid black',
            borderRadius: 5,
            width: 200,
            height: 15,
            backgroundColor: 'rgb(255, 0, 0)',
            transition: 'backgroundColor 0.2s',
          }}
        />
        <div
          ref={this.textRef}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textShadow: '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black',
          }}
        >
        </div>
      </div>
    );
  }
}

interface IEditableTextProps {
  text: string;
  onChange: (newText: string) => void;
}

class EditableText extends React.PureComponent<IEditableTextProps, {
  isEditing: boolean;
  text: string;
}> {
  constructor(props: IEditableTextProps) {
    super(props);
    this.state = { 
      isEditing: false,
      text: this.props.text,
    }   
  }

  render() {
    if (this.state.isEditing) {
      return <>
        <input value={this.state.text} onChange={(event) => this.setState({ text: event.target.value })} />
        <button style={{ marginLeft: 5 }} onClick={() => {
          this.setState({ isEditing: false }); 
          this.props.onChange(this.state.text);
        }}>Save</button>
        <button style={{ marginLeft: 5 }} onClick={() => this.setState({ isEditing: false, text: this.props.text })}>Cancel</button>
      </>;
    }   

    return <>
      {this.state.text}
      <span
        className='clickable'
        style={{ fontSize: '120%', marginLeft: 10, userSelect: 'none', cursor: 'pointer' }}
        onClick={() => this.setState({ isEditing: true })} 
      >   
        ✎
      </span>
    </>;
  }
}

interface IMainAppProps {
  history: ReturnType<typeof useHistory>;
}

class MainApp extends React.PureComponent<IMainAppProps, {
  battleRender: ((layout: Components.ILayout) => React.ReactNode) | null;
  handle: string;
  email: string;
  emailIsValid: boolean;
  payToLoseChecked: boolean;
  showCopied: boolean;
  showDead: boolean;
  showBots: boolean;
  rerollsFlash: boolean;
  emailFlash: boolean;
  modal: 'sign-up' | 'log-in' | null;
}> {
  gameConnection: GameConnection.GameConnection;
  doodads = new Map<number, React.ReactNode>();
  battleAnimationRef = React.createRef<Animation.BattleAnimation>();
  emailRef = React.createRef<HTMLInputElement>();
  passwordRef = React.createRef<HTMLInputElement>();
  repeatPasswordRef = React.createRef<HTMLInputElement>();

  constructor(props: IMainAppProps) {
    super(props);
    let handle = localStorage.getItem(HANDLE_LOCALSTORAGE_KEY);
    if (handle === null)
      handle = 'p' + Math.floor(1e6 * Math.random());
    this.state = {
      battleRender: null,
      handle,
      email: '',
      emailIsValid: true,
      payToLoseChecked: localStorage.getItem(PAY_TO_LOSE_LOCALSTORAGE_KEY) === '1',
      showCopied: false,
      showDead: localStorage.getItem('abWatchShowDead') === '1',
      showBots: localStorage.getItem('abWatchShowBots') === '1',
      rerollsFlash: false,
      emailFlash: false,
      modal: null,
      //inStore: window.location.href.includes('#store'),
    };
    this.gameConnection = new GameConnection.GameConnection(
      () => this.forceUpdate(),
      //this.playAnimation,
      handle,
    );
  }

  componentDidMount() {
    window.requestAnimationFrame(this.mainRafLoop);
  }

  onDragEnd = (result: Components.IDrop) => {
    console.log(result.source, '->', result.destination);
    this.gameConnection.moveCard(result.source.slotId, result.destination.slotId);
    if (result.source.slotId.startsWith('offerings-'))
      globalAudio.play('item-equip-6904');
  }

  renderGameOverScreen(gameState: GameConnection.IGameState) {
    const placeNumber = gameState.lobbyState.length - gameState.playerState.finish!;
    let suffix = 'th';
    if (placeNumber == 1)
      suffix = 'st';
    if (placeNumber == 2)
      suffix = 'nd';
    if (placeNumber == 3)
      suffix = 'rd';
    let color = 'white';
    let textShadow = '-2px -2px 4px black, 2px -2px 4px black, -2px 2px 4px black, 2px 2px 4px black';
    let emoji: string | null = null;
    if (placeNumber == 1) {
      color = 'gold';
      emoji = '🏆';
    }
    if (placeNumber >= 5) {
      color = 'black';
      textShadow = '-2px -2px 4px red, 2px -2px 4px red, -2px 2px 4px red, 2px 2px 4px red';
      emoji = '💀';
    }

    const balance = this.gameConnection.storeState === null ?
      { gold: 0, goldDelta: 0, silver: 0, silverDelta: 0 }
      : this.gameConnection.storeState.balance;

    let ratingSummary = <>
      No rating yet — {this.gameConnection.rating!.gamesPlayed}/{PLACEMENT_GAMES} placement games played
    </>;
    if (this.gameConnection.rating!.gamesPlayed === PLACEMENT_GAMES) {
      ratingSummary = <>
        <div>{PLACEMENT_GAMES_ENGLISH} placement games completed!</div>
        <div>Initial rating: {this.gameConnection.rating!.mmr}</div>
        <div>Initial rank: {Components.renderRank(this.gameConnection.rating!.rank, 2)}</div>
      </>;
    } else if (this.gameConnection.rating!.gamesPlayed > PLACEMENT_GAMES) {
      ratingSummary = <>
        <div>Rating: {this.gameConnection.rating!.mmr} (
          {this.gameConnection.rating!.mmrDelta > 0 && '+'}{this.gameConnection.rating!.mmrDelta}
        )</div>
        <div>Rank: {Components.renderRank(this.gameConnection.rating!.rank, 2)}</div>
      </>;
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
        <div style={{
          color,
          textShadow,
          fontSize: '500%',
        }}>
          {emoji} {placeNumber}{suffix} place {emoji}
        </div>
        <div style={{
          fontSize: '200%',
          textAlign: 'center',
          border: '2px solid black',
          marginTop: 30,
          padding: 15,
          borderRadius: 10,
          backgroundColor: '#888',
        }}>
          {ratingSummary}
          {balance.silverDelta > 0 && <div>
            Coins: +{balance.silverDelta}
          </div>}
        </div>
        <div style={{ marginTop: 30 }}>
          <button
            style={{ fontSize: '200%' }}
            onClick={() => this.gameConnection.leaveGame()}
          >
            Return to lobby
          </button>{/*<br/>
          <button
            style={{ fontSize: '200%' }}
            onClick={() => {
              this.gameConnection.leaveGame();
              const newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/')) + '/watch';
              window.history.replaceState(null, '', newUrl);
              this.forceUpdate();
            }}
          >
            Watch remaining players
          </button>*/}
        </div>
      </div>
    );
  }

  renderPregameChoices(layout: Components.ILayout, pregameChoices: GameConnection.ICharacterClass[]) {
    return (
      <div style={{
        display: 'flex',
        height: layout.height,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          fontSize: '300%',
          color: '#ddf',
          textShadow: '-1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black, 1px 1px 2px black',
          marginBottom: 20,
        }}>
          Choose your player class
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}>
          {pregameChoices.map((classDesc, classIndex) => {
            let totalGoldCost = 0;
            for (const row of classDesc.tileUnlockCosts) {
              for (const entry of row) {
                if (entry !== null) {
                  totalGoldCost += entry;
                }
              }
            }
            let opacity = 1;
            if (this.gameConnection.pregameChoice?.done)
              opacity = classIndex === this.gameConnection.pregameChoice.index ? 1 : 0.6;
            return (
              <div
                key={classIndex}
                style={{
                  border: '1px solid black',
                  borderRadius: 20,
                  padding: 10,
                  backgroundColor: '#888',
                  marginTop: 20,
                  width: 300,
                  height: 550,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontSize: '150%',
                  position: 'relative',
                  opacity,
                  transition: 'opacity 0.4s',
                }}
              >
                <div style={{
                  fontWeight: 'bold',
                  margin: 10,
                }}>{classDesc.name}</div>
                {classDesc.text}

                {/*
                <div style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                  fontSize: '120%',
                  color: '#c22',
                  textShadow: '-1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black, 1px 1px 2px black',
                }}>
                  {classDesc.hp}
                </div>
                <div style={{
                  position: 'absolute',
                  bottom: 20,
                  left: 20,
                  fontSize: '120%',
                  ...Components.goldText,
                }}>
                  {totalGoldCost}
                </div>
                */}

                {/* Show the board unlock costs */}
                <div style={{ flexGrow: 1 }} />
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  {classDesc.tileUnlockCosts.map((row, rowIndex) =>
                    <div key={rowIndex} style={{
                      display: 'flex',
                    }}>
                      {row.map((cost, costIndex) =>
                        <div key={costIndex} style={{
                          margin: 3,
                          width: 80,
                          height: 80,
                          border: '1px solid black',
                          borderRadius: 5,
                          backgroundColor: cost === null ? '#999' : '#444',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          ...Components.goldText,
                        }}>
                          {/*cost !== null && <Components.Icon
                            icon='coin'
                            value={cost}
                          />*/}
                          {cost}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <Components.Icon
                    scale={1.5}
                    icon='coin'
                    value={totalGoldCost}
                  />

                  <Components.SpecialButton
                    scale={0.4}
                    disable={this.gameConnection.pregameChoice?.done}
                    onClick={() => {
                      if (!this.gameConnection.pregameChoice?.done)
                        this.gameConnection.sendPregameChoices(classIndex);
                    }}
                  >
                    Choose
                  </Components.SpecialButton>

                  <Components.Icon
                    scale={1.6}
                    icon='def'
                    value={<span style={{ transform: 'translate(0px, -3px)' }}>{classDesc.hp}</span>}
                    style={{ fontSize: '25px' }}
                  />
                </div>
                {/*
                <div
                  style={{
                    //position: 'absolute',
                    //left: '50%',
                    //bottom: 10,
                    marginTop: 10,
                    border: '1px solid black',
                    borderRadius: 5,
                    padding: 10,
                    backgroundColor: '#88a',
                    //transform: 'translate(-50%, 0px)',
                    userSelect: 'none',
                    cursor: 'pointer',
                    opacity: this.gameConnection.pregameChoice?.done ? 0.6 : 1,
                  }}
                  onClick={() => {
                    if (!this.gameConnection.pregameChoice?.done)
                      this.gameConnection.sendPregameChoices(classIndex);
                  }}
                >
                  Choose
                </div>
                */}
              </div>
            );
          })}
        </div>

        <div style={{ marginTop: 40 }} />
        <TimerBar fadeInAt={15} timerDeadline={this.gameConnection.timerDeadline} />
      </div>
    );
  }

  renderGameState(layout: Components.ILayout, gameState: GameConnection.IGameState) {
    // If we're in an animated state then render that instead.
    if (
      this.gameConnection.animation !== null && (
        this.getElapsedAnimationTime() < this.gameConnection.animationDuration ||
        DEBUG_STAY_ON_ANIMATION
      )
    ) {
      if (layout.name === 'portrait') {
        return (
          <Components.RotationBox
            width={layout.width}
            height={layout.height}
            ccw
          >
            <Animation.BattleAnimation
              ref={this.battleAnimationRef}
              animation={this.gameConnection.animation}
              gameState={gameState}
              layout={{
                name: 'portrait',
                width: layout.height,
                height: layout.width,
              }}
            />
          </Components.RotationBox>
        );
      }
      return (
        <Animation.BattleAnimation
          ref={this.battleAnimationRef}
          animation={this.gameConnection.animation}
          gameState={gameState}
          layout={layout}
        />
      );
    }

    if (gameState.playerState.finish !== null)
      return this.renderGameOverScreen(gameState);

    const playerCards: React.ReactNode[] = gameState.lobbyState.map(
      (_, playerIndex) => <Components.PlayerCard
        layout={layout}
        key={playerIndex}
        gameState={gameState}
        playerIndex={playerIndex}
      />
    );
    // Sort our player card to the front.
    const temp = playerCards[0];
    playerCards[0] = playerCards[gameState.playerState.index];
    playerCards[gameState.playerState.index] = temp;

    let topBarRendering: React.ReactNode = null;
    if (layout.name === 'landscape') {
      topBarRendering = (
        <div style={{
          position: 'absolute',
          left: 0,
          height: layout.height,
          //backgroundColor: '#666',
          //borderBottom: '1px solid black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: 215,
          //paddingTop: 5,
          //marginBottom: 500,
          //paddingBottom: 5,
          //paddingBottom: 50,
        }}>
          {playerCards}
        </div>
      );
    } else {
      topBarRendering = (
        <div style={{
          width: '100%',
          //backgroundColor: '#666',
          //borderBottom: '1px solid black',
          height: 215,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}>
          {playerCards}
        </div>
      );
    }

    let reroll: React.ReactNode = (
      <Components.SpecialButton
        icon='reroll'
        vertical={layout.name == 'portrait'}
        scale={0.35}
        disable={gameState.playerState.gold < gameState.playerState.rerollCost || gameState.playerState.rerolls <= 0}
        onClick={() => this.gameConnection.reroll()}
        onClickWhenDisabled={() => {
          if (this.state.rerollsFlash === true || gameState.playerState.rerolls > 0)
            return;
          this.setState({ rerollsFlash: true });
          setTimeout(() => this.setState({ rerollsFlash: false }), 200);
        }}
        gold={gameState.playerState.rerollCost > 0 ? gameState.playerState.rerollCost : undefined}
      />
    );
    let lock: React.ReactNode = (
      <Components.SpecialButton
        icon='lock'
        vertical={layout.name == 'portrait'}
        scale={0.35}
        onClick={() => this.gameConnection.toggleLock()}
      />
    );

    const infoBoxStyle: React.CSSProperties = {
      border: '1px solid black',
      borderRadius: 5,
      padding: 5,
      backgroundColor: '#889',
    };

    return (
      <div style={{
        display: 'flex',
        height: layout.height,
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        {/* <PlayAudio name='music-1' fadeInTime={500} fadeOutTime={500} /> */}

        <Components.SpecialButton
          style={{
            position: 'absolute',
            top: layout.name === 'landscape' ? 10 : 235,
            right: 10,
          }}
          vertical={layout.name == 'portrait'}
          scale={layout.name === 'landscape' ? 0.4 : 0.25}
          onClick={() => {
            if (window.confirm('Resign the game?'))
              this.gameConnection.resign();
          }}
        >
          Resign
        </Components.SpecialButton>

        <Components.SpecialButton
          icon='fullscreen'
          style={{
            position: 'absolute',
            top: 120 + (layout.name === 'landscape' ? 10 : 235),
            right: 10,
          }}
          vertical={layout.name == 'portrait'}
          scale={layout.name === 'landscape' ? 0.4 : 0.25}
          onClick={() => {
            document.documentElement.requestFullscreen();
          }}
        />

        {gameState.players[gameState.playerState.index].readyToEndRoundEarly ||
          <Components.SpecialButton
            icon='check'
            style={{
              position: 'absolute',
              top: 240 + (layout.name === 'landscape' ? 10 : 235),
              right: 10,
            }}
            vertical={layout.name == 'portrait'}
            scale={layout.name === 'landscape' ? 0.4 : 0.25}
            onClick={() => {
              this.gameConnection.readyToEndRoundEarly();
            }}
          />
        }

        {/* Top bar */}
        {topBarRendering}

        {/* Board */}
        <div style={{
          marginTop: 10,
        }}>
          {gameState.playerState.board.map((row, y) =>
            <div key={y} style={{ display: 'flex' }}>
              {row.map((cell, x) =>
                <CardCell
                  key={x}
                  dropId={`board-${x}-${y}`}
                  canBuyTile={cell.cost !== null && gameState.playerState.gold >= cell.cost}
                  //disableDrag={cell.d !== null && cell.d.isHeavy}
                  desc={cell}
                  onClick={() => {
                    if (cell.cost !== null)
                      this.gameConnection.buyTile(`board-${x}-${y}`);
                  }}
                />
              )}
            </div>
          )}
        </div>

        {/* Round */}
        <div style={{
          border: '1px solid black',
          borderRadius: 5,
          backgroundColor: '#99b',
          width: 75,
          textAlign: 'center',
          position: 'absolute',
          fontSize: '250%',
          left: layout.name === 'landscape' ? 410 : 5,
          top: (layout.name === 'landscape' ? 20 : 230),
          transform: layout.name === 'landscape' ? undefined : 'scale(0.9)',
          //...Components.levelText,
        }}>
          {gameState.roundNumber}
        </div>

        {/* Timer bar */}
        <TimerBar timerDeadline={this.gameConnection.timerDeadline} />

        {/* Offerings */}
        <div style={{
          width: layout.name === 'landscape' ? undefined : 600,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: this.gameConnection.state?.playerState.offeringsLocked ? '#444' : undefined,
        }}>
          {gameState.playerState.offerings.map((cell, i) => <React.Fragment key={i}>
            <CardCell
              dropId={`offerings-${i}`}
              desc={cell}
              hideSlot
              disableDrop
              disableDrag={cell.d === null || cell.d.gold > gameState.playerState.gold}
              showGoldCost
              canBuyTile={cell.cost !== null && gameState.playerState.gold >= cell.cost}
              onClick={() => {
                if (cell.cost !== null)
                  this.gameConnection.buyTile(`offerings-${i}`);
              }}
            />
            {layout.name === 'portrait' && i === 2 && lock}
            {layout.name === 'portrait' && i === 4 && reroll}
          </React.Fragment>)}
        </div>

        {layout.name === 'landscape' && <div style={{
          position: 'absolute',
          top: 608,
          right: 220,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {reroll}
          {lock}
        </div>}

        {/* Trinkets */}
        <div style={{
          position: 'absolute',
          top: layout.name === 'landscape' ? 332 : 1185,
          right: layout.name === 'landscape' ? 175 : 110,
          display: 'flex',
          flexWrap: 'wrap',
          //backgroundColor: '#aaa',
          width: layout.name === 'landscape' ? TRINKET_SIZE * 3 + 3 * 10 : TRINKET_SIZE * 6 + 6 * 10,
          height: layout.name === 'landscape' ? TRINKET_SIZE * 2 + 2 * 10 : TRINKET_SIZE * 1 + 1 * 10,
          //padding: 5,
          //border: '1px solid black',
        }}>
          {gameState.playerState.trinkets.map((trinket, i) =>
            <Components.DragSlot
              key={i.toString()}
              slotId={`trinket-${i}`}
              disableDrag
            >
              {(isHighlit: boolean) =>
                <div
                  key={i}
                  style={{
                    margin: 5,
                  }}
                >
                  <BoardCard
                    desc={trinket.d}
                    forceTooltip={isHighlit}
                    // Shift the tooltip over a little for the leftmost trinket in portrait mode, to keep it on screen.
                    //xShift={layout.name === 'portrait' && i == 0 ? 20 : 0}
                    isTrinketSlot
                  />
                </div>
              }
            </Components.DragSlot>
          )}
        </div>

        {/* Gold indicator */}
        <Components.GoldCoin
          width={layout.name === 'landscape' ? 175 : 115}
          amount={gameState.playerState.gold}
          style={{
            position: 'absolute',
            right: layout.name === 'landscape' ? 30 : 2,
            top: layout.name === 'landscape' ? 600 : 1176,
          }}
        />

        <div style={{ flexGrow: 1 }} />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: layout.name === 'landscape' ? 15 : 20,
        }}>
          <Components.DragSlot slotId='trash' disableDrag>
            {(isHighlit: boolean) =>
              <Components.Icon
                icon='trash'
                style={{
                  marginTop: 20,
                  width: 1.0 * CARD_SIZE + 2,
                  height: 1.0 * CARD_SIZE + 2,
                }}
              />
            }
          </Components.DragSlot>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{
              ...infoBoxStyle,
            }}>
              Critter level: {gameState.playerState.shopLevel}
            </div>
            <Components.SpecialButton
              scale={layout.name === 'landscape' ? 0.6 : 0.45}
              icon='upgrade-critters'
              disable={gameState.playerState.shopUpgradeCost === null || gameState.playerState.gold < gameState.playerState.shopUpgradeCost}
              onClick={() => this.gameConnection.upgradeShop()}
              gold={gameState.playerState.shopUpgradeCost}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{
              ...infoBoxStyle,

            }}>
              Magic level: {gameState.playerState.trinketLevel}
            </div>
            <Components.SpecialButton
              scale={layout.name === 'landscape' ? 0.6 : 0.45}
              icon='upgrade-magic'
              disable={gameState.playerState.trinketUpgradeCost === null || gameState.playerState.gold < gameState.playerState.trinketUpgradeCost}
              onClick={() => this.gameConnection.upgradeTrinkets()}
              gold={gameState.playerState.trinketUpgradeCost}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{
              ...infoBoxStyle,
              color: this.state.rerollsFlash ? 'red' : 'black',
              transform: this.state.rerollsFlash ? 'scale(1.3)' : 'scale(1)',
              transition: 'transform 0.2s, color 0.2s',
            }}>
              Rerolls: {gameState.playerState.rerolls}/{gameState.playerState.rerollsMax}
            </div>
            <Components.SpecialButton
              scale={layout.name === 'landscape' ? 0.6 : 0.45}
              icon='upgrade-rerolls'
              disable={gameState.playerState.rerollUpgradeCost === null || gameState.playerState.gold < gameState.playerState.rerollUpgradeCost}
              onClick={() => this.gameConnection.upgradeRerolls()}
              gold={gameState.playerState.rerollUpgradeCost}
            />
          </div>
        </div>

        {/* Version information */}
        <div style={{ position: 'absolute', bottom: 5, right: 5, fontSize: '100%' }}>
          {this.gameConnection.isConnected() ? <>{VERSION_STRING} - {this.gameConnection.serverVersion}</>
            : <span style={{ color: 'red', fontSize: '150%' }}>Disconnected!</span>}
        </div>
      </div>
    );
  }

  getElapsedAnimationTime() {
    let elapsedAnimationTime = performance.now() / 1000 - this.gameConnection.animationStartTime;
    // // Double the speed of the animation after 30 seconds.
    // if (elapsedAnimationTime > 30)
    //  elapsedAnimationTime += elapsedAnimationTime - 30;
    return elapsedAnimationTime
  }

  mainRafLoop = (time: number) => {
    window.requestAnimationFrame(this.mainRafLoop);

    const elapsedAnimationTime = this.getElapsedAnimationTime();
    if (this.gameConnection.animation !== null) {
      if (elapsedAnimationTime > this.gameConnection.animationDuration && !DEBUG_STAY_ON_ANIMATION) {
        // End the animation.
        this.gameConnection.animation = null;
        this.forceUpdate();
        return;
      }

      // Animate!
      if (this.battleAnimationRef.current !== null)
        this.battleAnimationRef.current.updateAnimation(elapsedAnimationTime);
    }

    //for (const ref of this.watchAnimationRefs) {
    //  let e = 0;
    //  if (this.gameConnection.gameSummaries.length !== 0) {
    //    const summary = this.gameConnection.gameSummaries[this.gameConnection.gameSummaries.length - 1];
    //    e = performance.now() / 1000 - (summary.animationStartTime + this.gameConnection.serverTimeOffset);
    //  }
    //  if (ref.current !== null) {
    //    ref.current.updateAnimation(e);
    //  }
    //}
  }

  titleScreenRender = (page: '/' | '/how-to-play' | '/cards' | '/pay-to-lose' | '/watch' | '/account') => {
    let lobbyString = '';
    if (this.gameConnection.lobbyState !== null)
      lobbyString = this.gameConnection.lobbyState.lobby.map((player) => player.handle).join('\n');
    const shareLink = `https://autobattler.io/?c=${this.gameConnection.newInviteToken}`;

    const makeButton = (targetPage: string, label: React.ReactNode) => {
      return (
        <div
          className={page === targetPage ? 'selectedTopBarButton' : 'topBarButton'}
          onClick={() => {
            if (page !== targetPage)
              this.props.history.replace(targetPage);
          }}
          style={{
            textShadow: blackBorderTextShadow,
          }}
        >
          {label}
        </div>
      );
    }

    let content: React.ReactNode = null;
    switch (page) {
      case '/': {
        content = (
          <div style={{
            marginTop: 50,
            color: 'black',
          }}>
            {/* Here we show the three main bubbles */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{
                margin: 10,
                width: 400,
                fontSize: '150%',
                textAlign: 'center',
                border: '2px solid black',
                padding: 20,
                borderRadius: 10,
                backgroundColor: '#999',
              }}>
                <div>Games right now: {this.gameConnection.lobbyState?.games.count}</div>
                <div>Lobby: {this.gameConnection.lobbyState?.lobby.length}</div>
                <div style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>{lobbyString}</div>
              </div>

              <div style={{
                margin: 10,
                width: 600,
                fontSize: '200%',
                textAlign: 'center',
                border: '2px solid black',
                padding: 20,
                borderRadius: 10,
                backgroundColor: '#999',
              }}>
                <div style={{ fontSize: '28px' }}>
                  Player handle:
                  <input
                    type='text'
                    value={this.state.handle}
                    style={{
                      width: 250,
                      marginLeft: 10,
                      //marginTop: 15,
                      fontSize: '80%',
                    }}
                    onChange={(event) => {
                      this.setState({ handle: event.target.value });
                      localStorage.setItem(HANDLE_LOCALSTORAGE_KEY, event.target.value);
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <button
                    style={{ fontSize: '32px' }}
                    onClick={() => this.gameConnection.joinLobby(this.state.handle.trim(), this.state.payToLoseChecked)}
                    disabled={!(isValidHandle(this.state.handle.trim()) && this.gameConnection.isConnected())}
                  >
                    Join lobby
                  </button>
                </div>
                <div style={{
                  marginTop: 10,
                  textAlign: 'center',
                  border: '2px solid black',
                  padding: 15,
                  borderRadius: 10,
                  backgroundColor: '#888',
                }}>
                  {this.gameConnection.rating === null ?
                    '...' :
                    <>
                      {this.gameConnection.rating!.gamesPlayed < PLACEMENT_GAMES ?
                        <div style={{ fontSize: '80%', opacity: 0.7, marginBottom: 10 }}>
                          No rating yet<br/>(complete {PLACEMENT_GAMES} games to get a rating)
                        </div> :
                        <div>Rating: {this.gameConnection.rating!.mmr} — Rank: {Components.renderRank(this.gameConnection.rating!.rank, 1.5)}</div>
                      }
                      Games played: {this.gameConnection.rating!.gamesPlayed}
                    </>
                  }
                  <div style={{
                    marginTop: 10,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '24px',
                  }}>
                    <table style={{ textAlign: 'left' }}>
                      <tbody>
                        <tr><td style={{textAlign: 'center' }}>Diamond</td><td>{<Components.Icon scale={1.2} icon='diamond' />}</td><td>rating ≥2500</td><td>{this.gameConnection.percentiles[4]}</td></tr>
                        <tr><td style={{textAlign: 'center' }}>Platinum</td><td>{<Components.Icon scale={1.2} icon='platinum' />}</td><td>rating ≥2000</td><td>{this.gameConnection.percentiles[3]}</td></tr>
                        <tr><td style={{textAlign: 'center' }}>Gold</td><td>{<Components.Icon scale={1.2} icon='gold' />}</td><td>rating ≥1500</td><td>{this.gameConnection.percentiles[2]}</td></tr>
                        <tr><td style={{textAlign: 'center' }}>Silver</td><td>{<Components.Icon scale={1.2} icon='silver' />}</td><td>rating ≥1000</td><td>{this.gameConnection.percentiles[1]}</td></tr>
                        <tr><td style={{textAlign: 'center' }}>Bronze</td><td>{<Components.Icon scale={1.2} icon='bronze' />}</td><td>rating ≥500</td><td>{this.gameConnection.percentiles[0]}</td></tr>
                        <tr><td style={{textAlign: 'center' }}>Wood</td><td>{<Components.Icon scale={1.2} icon='wood' />}</td><td>rating ≥0</td><td></td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                style={{
                  //position: 'absolute',
                  //top: 5,
                  //right: 20,
                  width: 300,
                  fontSize: '120%',
                  textAlign: 'center',
                  border: '2px solid black',
                  margin: 10,
                  padding: 20,
                  borderRadius: 10,
                  backgroundColor: '#999',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', fontSize: '120%' }}>Leaderboard</span>
                <table style={{ marginTop: 10 }}>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>Player</td>
                      <td>Rating</td>
                      {/*<td>Rank</td>*/}
                    </tr>
                    {this.gameConnection.leaderboard.slice(0, 10).map((entry, index) =>
                      <tr key={index}>
                        <td>#{index + 1}</td>
                        <td>{entry.handle}</td>
                        <td>{entry.mmr}</td>
                        {/*<td>{Components.renderRank(entry.rank)}</td>*/}
                      </tr>
                    )}
                  </tbody>
                </table>
                <div style={{ marginTop: 10, fontSize: '16px' }}>
                  (Must have at least 10 games.)
                </div>
              </div>
            </div>

            {this.gameConnection.newInviteToken !== null &&
              <div style={{
                backgroundColor: '#aaa',
                padding: 10,
                margin: 10,
                border: '2px solid black',
                borderRadius: 10,
                fontSize: '120%',
                textAlign: 'center',
                position: 'relative',
              }}>
                Invite new players to join using this link:<br/>
                <a href={shareLink}>{shareLink}</a><br/>
                <button style={{ position: 'relative', fontSize: '120%' }} onClick={() => {
                  navigator.clipboard.writeText(shareLink);
                  this.setState({ showCopied: true });
                  setTimeout(() => this.setState({ showCopied: false }), 1000);
                }}>
                  Copy to clipboard
                  <div style={{
                    position: 'absolute',
                    left: '105%',
                    top: '50%',
                    backgroundColor: '#aad',
                    padding: 5,
                    border: '1px solid #666',
                    transform: 'translate(0px, -50%)',
                    opacity: this.state.showCopied ? 1 : 0,
                    transition: 'opacity 0.05s',
                  }}>
                    Copied!
                  </div>
                </button>
              </div>
            }
          </div>
        );
        break;
      }

      case '/how-to-play': {
        content = (
          <div style={{
            fontSize: '160%',
            textShadow: blackBorderTextShadow,
            width: '100%',
            height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
            overflow: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <div style={{ margin: 50, width: 800 }}>
              Each game is a tournament of eight players.
              Your goal is to assemble a team of critters to defeat your opponents in a series of battles.
              Buy critters from the shop by dragging them from the shop onto an open tile.
            </div>

            <img
              style={{
                width: 1000,
              }}
              src={process.env.PUBLIC_URL + '/assets/help2-marked2-crop.jpg'}
            />

            <div style={{ margin: 25, width: 800 }}>
              <p>
                After you buy critters you battle another player, and deal them damage if your critters win.
                Be the last player standing to win the tournament.
              </p>
              <p>
                You can also:
                <ul>
                  <li>Upgrade critters or magic to get better cards in your shop.</li>
                  <li>Upgrade rerolls to be able to reroll your shop more times.</li>
                  <li>Unlock additional slots on the field or in the shop.</li>
                  <li>Lock your shop to keep cards after the battle.</li>
                </ul>
              </p>
            </div>
          </div>
        );
        break;
      }

      case '/cards': {
        content = <div style={{
          height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
          overflow: 'scroll',
        }}>
          <div style={{
            margin: 50,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {this.gameConnection.allCards.map((desc, i) =>
              <BoardCard
                desc={desc}
                showGoldCost={true}
              />
            )}
          </div>

          <div style={{ margin: 50, textAlign: 'center' }}>
            <div style={{ fontSize: '200%' }}>Characters:</div>
            <div style={{ margin: 100 }}>
              (Coming soon)
            </div>
          </div>
        </div>;
        break;
      }

      case '/pay-to-lose': {
        content = (
          <div style={{
            marginTop: 50,
            fontSize: '200%',
          }}>
            Coming soon
          </div>
        );
        break;
      }

      case '/watch': {
        content = (
          <div style={{
            marginTop: 50,
          }}>
            Also coming soon.
          </div>
        );
        break;
      }

      case '/account': {
        content = (
          <div style={{
            maxWidth: 600,
            border: '1px solid black',
            borderRadius: 10,
            backgroundColor: '#aaa',
            marginTop: 50,
            padding: 20,
            color: 'black',
            fontSize: '130%',
          }}>
            {this.gameConnection.accountEmail !== null &&<button onClick={() => {
              this.gameConnection.logOut();
              this.props.history.replace('/');
            }}>Log out</button>}

            Handle: <EditableText text={this.state.handle} onChange={(newText) => {
              this.setState({ handle: newText });
              localStorage.setItem(HANDLE_LOCALSTORAGE_KEY, newText);
            }} />
            <br/>
            Account ID: {this.gameConnection.accountId}<br/>
            {this.gameConnection.accountEmail === null ? <>
              <div style={{ marginTop: 10 }}>
                <b>You're using an instant-account.</b><br/>
                Sign up with an email address to be able to log in on another device, and not lose your account if you clear your browser's storage.
              </div>
              <div style={{ marginTop: 10 }}>
                <span style={{
                  fontWeight: 'bold',
                  color: this.state.emailFlash ? 'red' : (this.state.emailIsValid ? 'black' : '#a00'),
                  transition: 'color 0.2s, transform 0.2s',
                }}>Email:</span>
                <input
                  type='text'
                  value={this.state.email}
                  style={{
                    width: 400,
                    marginLeft: 10,
                    //marginTop: 15,
                    fontSize: '80%',
                    //border: this.state.emailIsValid ? undefined : '1px solid red',
                  }}
                  onChange={(event) => this.setState({
                    email: event.target.value.trim(),
                    emailIsValid: event.target.value.trim().match(/^(|\S+@\S+\.\S+)$/) !== null,
                  })}
                />
                <br/>
                <button style={{ fontSize: '20px', marginTop: 20 }} onClick={() => {
                  if (this.state.email === '' || !this.state.emailIsValid) {
                    if (this.state.emailFlash === true)
                      return;
                    this.setState({ emailFlash: true });
                    setTimeout(() => this.setState({ emailFlash: false }), 200);
                  }
                  this.gameConnection.resetPassword('sign-up', this.state.email);
                }}>
                  Sign up
                </button>
              </div>
            </>
            : <>
              Email: {this.gameConnection.accountEmail} {this.gameConnection.accountIsVerified ? '(verified)' : '(unverified)'}
            </>}
          </div>
        );
        break;
      }
    }

    return (
      <div style={{
        display: 'flex',
        color: 'white',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <div style={{
          display: 'flex',
          width: '100%',
          height: TOP_BAR_HEIGHT,
          backgroundColor: '#444',
          alignItems: 'center',
          borderBottom: '1px solid #888',
        }}>
          {makeButton('/', 'autobattler.io')}
          {makeButton('/how-to-play', 'How to play')}
          {makeButton('/cards', 'Cards')}
          {makeButton('/pay-to-lose', 'Pay to lose')}
          {makeButton('/watch', 'Watch')}

          <div style={{ flexGrow: 1 }} />

          {this.gameConnection.accountEmail === null ? <>
            <div
              className={'topBarButton'}
              onClick={() => {
                this.setState({ modal: this.state.modal === 'sign-up' ? null : 'sign-up' });
              }}
              style={{ textShadow: blackBorderTextShadow }}
            >
              Sign up
            </div>
            <div
              className={'topBarButton'}
              onClick={() => {
                this.setState({ modal: this.state.modal === 'log-in' ? null : 'log-in' });
              }}
              style={{ textShadow: blackBorderTextShadow }}
            >
              Log-in
            </div>
          </> : makeButton('/account', 'Account')}
        </div>

        {this.state.modal !== null &&
          <div style={{
            position: 'absolute',
            border: '1px solid gray',
            background: '#ccc',
            padding: 10,
            color: 'black',
            zIndex: 10,
            top: 120,
            right: 10,
            fontSize: '120%',
          }}>
            <div>
              <div style={{ float: 'right' }} onClick={() => this.setState({ modal: null })}>
                ⨂
              </div>

              <b>{this.state.modal === 'log-in' ? 'Log in' : 'Sign up'}</b>


              {this.gameConnection.signUpMessage?.kind === 'sign-up-successful' ?
                <div>
                  Successfully send verification email!
                  Check {this.gameConnection.signUpMessage?.email} to verify your email and complete registration.
                </div>
              : <>
                <table>
                  <tbody>
                    <tr>
                      <td>Email:</td>
                      <td><input ref={this.emailRef} style={{ width: 300 }} name="username" required type="email"></input></td>
                    </tr>
                    <tr>
                      <td>Password:</td>
                      <td><input ref={this.passwordRef} style={{ width: 300 }} name="password" required type="password"></input></td>
                    </tr>
                    {this.state.modal === 'sign-up' && 
                      <tr>
                        <td>Repeat password:</td>
                        <td><input ref={this.repeatPasswordRef} style={{ width: 300 }} name="repeat-password" required type="password"></input></td>
                      </tr>
                    }
                  </tbody>
                </table>
                <button style={{ fontSize: '100%' }} onClick={(event) => {
                  if (this.state.modal === 'sign-up' && this.passwordRef.current?.value !== this.repeatPasswordRef.current?.value) {
                    // Passwords must match!
                    return;
                  }
                  if (this.state.modal === 'log-in') {
                    this.gameConnection.passwordLogin(this.emailRef.current!.value, this.passwordRef.current!.value);
                  } else {
                    this.gameConnection.signUp(this.emailRef.current!.value, this.passwordRef.current!.value);
                  }
                }}>Submit</button>
              </>}

              {this.gameConnection.signUpMessage?.kind === 'email-already-in-use' && <div>
                Email address already in use — <a href="#" onClick={() => {
                  this.gameConnection.resetPassword('reset', this.gameConnection.signUpMessage!.email);
                }}>recover password</a>
              </div>}

              {this.gameConnection.signUpMessage?.kind === 'failed-login' && <div>
                Invalid username/password — <a href="#" onClick={() => {
                  this.gameConnection.resetPassword('reset', this.gameConnection.signUpMessage!.email);
                }}>recover password</a>
              </div>}
            </div>
          </div>
        }

        {content}

        <div style={{
          position: 'absolute',
          top: 80,
          left: 5,
          fontSize: '120%',
          zIndex: 20,
          textShadow: blackBorderTextShadow,
        }}>
          Client: {VERSION_STRING} — Server: {this.gameConnection.serverVersion}
        </div>
        <div style={{
          position: 'absolute',
          top: 80,
          right: 5,
          fontSize: '120%',
          zIndex: 20,
          textShadow: blackBorderTextShadow,
        }}>
          {this.gameConnection.webSocket?.readyState === WebSocket.OPEN && 'Connected'}
          {this.gameConnection.webSocket?.readyState === WebSocket.CONNECTING && 'Connecting...'}
          {this.gameConnection.isConnected() ||
            <span style={{ color: 'red', fontSize: '120%' }}>Disconnected!</span>}
        </div>
      </div>
    );

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //height: layout.height,
      }}>
        <div style={{ position: 'absolute', bottom: 20, left: 20, fontSize: '150%' }}>
          Client: {VERSION_STRING} — Server: {this.gameConnection.serverVersion}
        </div>
        <div style={{ position: 'absolute', bottom: 20, right: 20, fontSize: '150%' }}>
          {this.gameConnection.webSocket?.readyState === WebSocket.OPEN && 'Connected'}
          {this.gameConnection.webSocket?.readyState === WebSocket.CONNECTING && 'Connecting...'}
          {this.gameConnection.isConnected() ||
            <span style={{ color: 'red', fontSize: '150%' }}>Disconnected!</span>}
        </div>

        {ENABLE_STORE && <div style={{
          position: 'absolute',
          left: 100,
          top: 380,
          transform: 'scale(200%)',
        }}>
          <input
            type='checkbox'
            checked={this.state.payToLoseChecked}
            onChange={(event) => {
              this.setState({ payToLoseChecked: event.target.checked });
              localStorage.setItem(PAY_TO_LOSE_LOCALSTORAGE_KEY, event.target.checked ? '1' : '0');
            }}
          />
          Pay-to-lose
        </div>}

        {/* Store link */}
        {ENABLE_STORE && <Components.SpecialButton
          scale={0.5}
          style={{
            position: 'absolute',
            top: 30,
            left: 30,
          }}
          onClick={() => {
            this.props.history.push('/pay-to-lose');
            this.gameConnection.getStoreState();
          }}
        >
          <div style={{ fontSize: '90%', textAlign: 'center' }}>Pay to Lose<br/>Store</div>
        </Components.SpecialButton>}
        
        {/*
        <Link
          style={{
            top: 30,
            left: 30,
            width: 240,
            height: 100,
            border: '1px solid black',
            backgroundColor: '#88a',
            fontSize: '150%',
            //textShadow: '0px 0px 2px white',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            userSelect: 'none',
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'black',
          }}
          //onClick={() => {
          //  //this.setState({ inStore: true });
          //  this.gameConnection.getStoreState();
          //}}
          to='/pay-to-lose'
        >
          Pay-to-lose store
        </Link>
        */}

        {/* Credits link */}
        {ENABLE_CREDITS && <Components.SpecialButton
          scale={0.5}
          style={{
            position: 'absolute',
            top: 190,
            left: 30,
          }}
          onClick={() => this.props.history.push('/credits')}
        >
          Credits
        </Components.SpecialButton>}
      </div>
    );
  }

  renderStore = (kind: 'success' | 'cancel' | 'neutral') => {
    const owned: string[] = this.gameConnection.storeState === null ? [] : this.gameConnection.storeState.owned;

    return <>
      {/* Back button */}
      <Components.SpecialButton
        scale={0.5}
        style={{
          position: 'absolute',
          top: 30,
          left: 30,
        }}
        onClick={() => this.props.history.goBack()}
      >
        Back
      </Components.SpecialButton>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //height: layout.height,
      }}>
        <div style={{
          fontSize: '150%',
          textAlign: 'left',
          //width: Math.min(800, layout.width - 50),
          border: '2px solid black',
          padding: 30,
          borderRadius: 10,
          backgroundColor: '#999',
        }}>
          <div style={{
            fontSize: '130%',
            fontWeight: 'bold',
            marginBottom: 10,
          }}>Pay-to-lose store</div>

          There are two kinds of microtransactions available:
          <ul>
            <li>Pointless cosmetics</li>
            <li>Pay-to-lose items that exclusively make you weaker with no redeeming benefits whatsoever</li>
          </ul>
          Buy <span style={Components.ppText}>pointless coins</span> to get started:
          <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}>
            {[
              [5, 500, ''],
              [10, 1100, '(+10%)'],
              [25, 2875, '(+15%)'],
              [100, 12000, '(+20%)'],
            ].map(([price, ppCount, priceBreak]) =>
              <div
                key={price}
                className='hoverButton'
                style={{
                  width: 150,
                  height: 190,
                  border: '1px solid black',
                  borderRadius: 10,
                  backgroundColor: '#aad',
                  //display: 'flex',
                  //justifyContent: 'center',
                  textAlign: 'center',
                  paddingTop: 20,
                  //alignItems: 'center',
                }}
                onClick={() => {
                  this.gameConnection.purchaseTokens(`b-${price}`);
                }}
              >
                Buy ${price}<br/><br/>
                Get {ppCount.toLocaleString()} <span style={Components.ppText}>pointless coins</span><br/>
                {priceBreak}
              </div>
            )}
          </div>

          <div style={{
            textAlign: 'center',
            fontSize: '130%',
            marginBottom: 20,
          }}>
            Balance: {this.gameConnection.storeState?.balance.gold} <span style={Components.ppText}>pointless coins</span><br/>
            Balance: {this.gameConnection.storeState?.balance.silver} <span style={Components.ppText}>pointless points</span>
          </div>

          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
            {this.gameConnection.storeState?.forSale.map((entry) =>
              <div
                key={entry.itemName}
                className={owned.includes(entry.itemName) ? undefined : 'hoverButton'}
                style={{
                  width: 150,
                  height: 100,
                  padding: 10,
                  border: '1px solid black',
                  borderRadius: 10,
                  backgroundColor: owned.includes(entry.itemName) ? '#9c9' : '#99c',
                }}
                onClick={() => {
                  if (!owned.includes(entry.itemName) && window.confirm(`Buy ${entry.itemDisplayName} for ${entry.goldCost} pointless points?`)) {
                    this.gameConnection.buyItem(entry.itemName);
                  }
                }}
              >
                {entry.itemDisplayName}: {entry.goldCost} <b>PC</b><br/>
                {entry.description}
              </div>
            )}
          </div>
        </div>
      </div>
    </>;
  }

  renderWatchPage(layout: Components.ILayout) {
    return (
      <div>
        <div style={{ color: 'white' }}>
          Show dead: <input
            type='checkbox'
            checked={this.state.showDead}
            onChange={(event) => {
              this.setState({ showDead: event.target.checked });
              localStorage.setItem('abWatchShowDead', event.target.checked ? '1' : '0');
            }}
          />
          &nbsp;&nbsp;&nbsp;
          Show bots: <input
            type='checkbox'
            checked={this.state.showBots}
            onChange={(event) => {
              this.setState({ showBots: event.target.checked });
              localStorage.setItem('abWatchShowBots', event.target.checked ? '1' : '0');
            }}
          />
        </div>
        {/*<button onClick={() => {
          this.gameConnection.send({ kind: 'fetch-all-games' });
        }}>Refresh</button>*/}

        <div>
          {this.gameConnection.gameSummaries.slice(-1).map((gameSummary, gameIndex) =>
            <div key={gameIndex} style={{
              margin: 10,
              border: '2px solid black',
              padding: 10,
              backgroundColor: '#999',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
              {gameSummary.playerHandles.map((playerHandle, playerIndex) => {
                if (gameSummary.playerStates[playerIndex] === null) {
                  return 'Initial mode';
                }

                if (gameSummary.playerStates[playerIndex]!.playerState.hp <= 0 && !this.state.showDead)
                  return null;
                let isBot = /^[A-Z][a-z]+[A-z][a-z]+[0-9][0-9]$/.test(playerHandle);
                if (isBot && !this.state.showBots)
                  return null;

                return (
                  <div key={playerIndex} style={{
                    margin: 10,
                    border: '1px solid black',
                    padding: 10,
                    backgroundColor: '#aaa',
                    position: 'relative',
                  }}>
                    {gameSummary.playerStates[playerIndex]!.players[playerIndex].readyToEndRoundEarly &&
                      <div style={{
                        position: 'absolute',
                        right: -10,
                        top: -10,
                        zIndex: 2,
                        fontSize: '500%',
                        color: 'green',
                        textShadow: '-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
                      }}>
                        ✓
                      </div>
                    }

                    <div>
                      <b>{playerHandle}</b> — {gameSummary.playerStates[playerIndex]!.lobbyState[playerIndex].class} —
                      HP: {gameSummary.playerStates[playerIndex]!.playerState.hp} —
                      Gold: {gameSummary.playerStates[playerIndex]!.playerState.gold} —
                      S-{gameSummary.playerStates[playerIndex]!.playerState.shopLevel} —
                      T-{gameSummary.playerStates[playerIndex]!.playerState.trinketLevel}
                    </div>
                    <div>
                      <div style={{
                        marginTop: 10,
                      }}>
                        {gameSummary.playerStates[playerIndex]!.playerState.board.map((row, y) =>
                          <div key={y} style={{ display: 'flex' }}>
                            {row.map((cell, x) =>
                              <CardCell
                                key={x}
                                dropId={`board-${x}-${y}`}
                                desc={cell}
                                disableDrag
                                disableDrop
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      //backgroundColor: '#aaa',
                      //width: Components.TRINKET_SIZE(layout) * 3 + 3 * 10,
                      //height: Components.TRINKET_SIZE(layout) * 2 + 2 * 10,
                      //padding: 5,
                      //border: '1px solid black',
                    }}>
                      {gameSummary.playerStates[playerIndex]!.playerState.trinkets.map((trinket, i) =>
                        <Components.Trinket
                          key={i}
                          desc={trinket.d}
                          layout={layout}
                          // Shift the tooltip over a little for the leftmost trinket in portrait mode, to keep it on screen.
                          xShift={0}
                        />
                      )}
                    </div>

                    <div style={{ position: 'relative' }}>
                      <Animation.BattleAnimation
                        //ref={this.watchAnimationRefs[playerIndex]}
                        animation={gameSummary.animations[playerIndex]}
                        gameState={gameSummary.playerStates[playerIndex]!}
                        refreshKey={JSON.stringify(gameSummary.animations[playerIndex]).length}
                        layout={layout}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderCredits = (layout: Components.ILayout) => {
    return (
      <div>
        {/* Back button */}
        <Components.SpecialButton
          scale={0.5}
          style={{
            position: 'absolute',
            top: 30,
            left: 30,
          }}
          onClick={() => this.props.history.goBack()}
        >
          Back
        </Components.SpecialButton>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: layout.height,
          fontSize: '200%',
        }}>
          <div style={{
            width: 800,
            backgroundColor: '#aaa',
            border: '1px solid black',
            borderRadius: 10,
            padding: 30,
          }}>
            Created/programmed by <a href="https://peter.website">Peter Schmidt-Nielsen</a><br/>
            Card illustrations by <a href="https://twitter.com/norshiex">Norshie</a><br/>
            Icons by Wenbo<br/>
            Neverland by Alexander Nakarada | https://www.serpentsoundstudios.com<br/>
            Music from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=6904">Pixabay</a><br/>
            <br/>
            Play testing/design thanks to:<br/>
            Erik Schmidt-Nielsen, other names here
          </div>
        </div>
      </div>
    );
  }

  renderMain = () => {
    if (this.gameConnection.state !== null) {
      return this.wrapWithRescaler((layout) => this.renderGameState(layout, this.gameConnection.state!));
    } else if (this.gameConnection.pregameClassChoices !== null) {
      return this.wrapWithRescaler((layout) => this.renderPregameChoices(layout, this.gameConnection.pregameClassChoices!));
    } else {
      return this.titleScreenRender('/');
    }
  }

  wrapWithRescaler(render: (layout: Components.ILayout) => React.ReactNode) {
    return (
      <Components.Rescaler
        layouts={[
          { width: 1600, height: 1000, name: 'landscape' },
          { width: 700, height: 1500, name: 'portrait' },
        ]}
        onDragEnd={this.onDragEnd}
      >
        {render}
      </Components.Rescaler>
    );
  }

  render() {
    return (
      <Switch>
        {/* <Route path='/pay-to-lose/success' render={() => this.renderStore('success')} /> */}
        {/* <Route path='/pay-to-lose/cancel' render={() => this.renderStore('cancel')} /> */}
        {/* <Route path='/pay-to-lose' render={() => this.renderStore('neutral')} /> */}
        <Route path='/credits' render={() => this.wrapWithRescaler(this.renderCredits)} />
        <Route path='/how-to-play' render={() => this.titleScreenRender('/how-to-play')} />
        <Route path='/cards' render={() => this.titleScreenRender('/cards')} />
        <Route path='/pay-to-lose' render={() => this.titleScreenRender('/pay-to-lose')} />
        <Route path='/watch' render={() => this.titleScreenRender('/watch')} />
        <Route path='/account' render={() => this.titleScreenRender('/account')} />
        {/* <Route path='/watch' render={() => this.renderWatchPage(layout)} /> */}
        <Route path='/' render={this.renderMain} />
      </Switch>
    );
  }
}

function WithHistory() {
  const history = useHistory();
  return <MainApp history={history} />;;
}

function App() {
  // Check if we're connecting via an invite link.
  const inviteCode = new URL(window.location.href).searchParams.get("c");
  if (inviteCode !== null) {
    localStorage.setItem(GameConnection.AUTOBATTLER_INVITE_TOKEN_LOCALSTORAGE_KEY, inviteCode);
    // Remove the invite query from the URL.
    const newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
    window.history.replaceState(null, '', newUrl);
  }

  const inviteToken = localStorage.getItem(GameConnection.AUTOBATTLER_INVITE_TOKEN_LOCALSTORAGE_KEY);
  if (inviteToken !== null) {
    return (
      <Router>
        <WithHistory />
      </Router>
    );
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontSize: '200%',
      color: 'white',
      textShadow: '-1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black, 1px 1px 2px black',
    }}>
      <div style={{ textAlign: 'center', width: 900 }}>
        <h2>Hello!</h2>
        <p>
          <code style={{ fontSize: '130%' }}>autobattler.io</code> is invite-only right now
        </p>
        <p>
          Feel free to email me (Peter Schmidt-Nielsen)
          at <a href="mailto:schmidtnielsenpeter@gmail.com" style={{ color: 'white' }}>
            schmidtnielsenpeter@gmail.com
          </a> for
          an invite link.
        </p>
      </div>
    </div>
  );
}

export default App;
